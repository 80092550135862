body {
  margin: 0;
  padding: 0;
  /* font-family: sans-serif;*/
  font-family: apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  background-color: #ededed;
}

.container {
  font-size: 18px;
}

.imagicon:first-of-type {
  margin-left: 0;
}

.imagicon {
  margin-left: 20px;
  cursor: pointer;
}

.eye {
  opacity: 0.6;
  width: 18px;
}

.table-row:hover {
  background: #d8d8d8;
}

.tooltip {
  font-family: Rubik;
  font-size: 12.8px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 5px !important;
  height: 24.9px;

  color: #000000;
  border-radius: 20px !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
}

.tooltip-dashboard {
  font-family: Rubik;
  font-size: 12.8px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 5px !important;
  color: #000000;
  border-radius: 20px !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
}

.font-text-table {
  font-family: Rubik;
  font-size: 13.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
}

.card-item-font {
  display: inline-block;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  margin-left: 5px;
  color: #acacac;
}

.text-header-row:hover {
  cursor: pointer;
  color: #403f4c;
}

.text-header-row {
  font-family: Rubik;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.area-button {
  height: 2px;
  border: solid 1px #979797;
}

.btn-area:hover {
  opacity: 0.39;
}

.table-row {
  font-family: Rubik;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.remover-edit-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ul-pagination {
  margin-top: 2%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-pagination {
  width: 8.3px;
  height: 10.5px;
  object-fit: contain;
}

.header-label {
  font-family: Rubik;
  font-size: 18.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
}

.imagicon-token {
  margin: 0;
  padding: 0;
  width: 25px;
  margin-left: 20px;
}

.teste {
  border-radius: 50px !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
  height: 51px;
}

.second-header-body {
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnicon:hover {
  opacity: 0.36;
  cursor: pointer;
  color: #403f4c;
}

.pagination-text {
  font-family: 'Times New Roman', Times, serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
  margin-left: 20px;
  margin-right: 20px;
}

.pagination-text-number {
  width: 8px;
  height: 14px;
  font-family: Rubik;
  font-size: 14.2px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
  margin-left: 10px;
  margin-right: 10px;
}

.pagination-text-number:hover {
  color: #000000;
}

.size-pagination {
  font-family: Rubik;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

.div-pagination {
  height: 1.4px;
  opacity: 0.1;
  border: solid 0.7px #000000;
  margin-bottom: 18px;
}
.card-division {
  width: none;
  height: 8px;
  background-color: rgba(64, 63, 76, 0.3);
}

.arrow-academy:hover {
  fill: #403f4c;
  cursor: pointer;
}

.step-academy:hover {
  opacity: 0.39;
  fill: #403f4c;
  cursor: pointer;
}

.btn-login:hover {
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.11);
  font-size: 12px;
}

.foto-perfil {
  margin-left: 15px;
  height: 14px;
  margin-top: 10px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}
.cardbodyitem {
  cursor: pointer;
}

.dialog-text {
  height: 24px;
  opacity: 0.53;
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: center;
  color: #616161;
}

.dialogyesno-body {
  padding: 20px;
}

.btn-dialog {
  width: 100px;
  height: 40px;
  margin-left: 20px;
  margin-right: 0px;
  margin-top: 40px;
  border-radius: 2.1px;
  background-color: #e5156b;
  border: none;
  color: white;
}
.btn-dialog-alert {
  width: 100px;
  height: 40px;
  margin-left: 30%;
  margin-right: 0px;
  margin-top: 40px;
  border-radius: 2.1px;
  background-color: #e5156b;
  border: none;
  color: white;
}
.btn-dialog:hover {
  cursor: pointer;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
}
.btn-dialog-alert:hover {
  cursor: pointer;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
}

.btn-card-curso {
  width: 100%;
  height: 30px;
  margin-top: 2px;
  border-radius: 0px 0px 10px 10px;
  border: none;
  background-color: #ffffff;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
  opacity: 0.36;
  border-top: solid 1px #979797;
}

.btn-card-curso:hover {
  background-color: rgba(172, 172, 172, 0.3);
  cursor: pointer;
}

.text-actions {
  height: 12px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.label-link {
  color: '#acacac';
}

.text-area-content {
  border: none;
  width: 59px;
  height: 12px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
  width: 100%;
  height: 150px;
  background-color: #ffffff;
}

.text-place {
  width: 109px;
  height: 20px;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}
.btn-click:hover {
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.11);
  font-size: 12px;
  cursor: pointer;
}
.btn-rascunho {
  width: 147.4px;
  height: 27px;
  border-radius: 2.1px;
  border: solid 1px #403f4c;
  background-color: white;
  margin-left: 10px;
}
.btn-rascunho:hover {
  cursor: pointer;
}
.text-avaible-course {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #1bab60;
}

.text-disable-course {
  height: 14px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #b8b8b8;
}

.text-default-disable-course {
  width: 111px;
  height: 14px;
  margin-top: 10px;
  margin-left: 70px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #b8b8b8;
}
.svg-access {
  fill: red;
}

.name-file-label {
  height: 14px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 5px;
  color: #403f4c;
}
.btn-add-file {
  width: 216px;
  height: 32px;
  border: none;
  border-radius: 7px;
  margin-top: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.32);
}
.btn-add-file:hover {
  cursor: pointer;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.32);
}
.btn-text-label {
  height: 12px;
  font-family: Rubik;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.length-field-text {
  margin-top: 10px;
  height: 14px;
  opacity: 0.37;
  font-family: Rubik;
  font-size: 14.2px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #403f4c;
}
.label-load {
  width: 304px;
  height: 28px;
  font-family: Rubik;
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
  margin-top: 32%;
}

.label-modulos {
  width: 304px;
  height: 28px;
  font-family: Rubik;
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
  margin-bottom: 5px;
  margin-top: 25px;
  margin-left: 35%;
}
.divisor-modulos {
  background-color: #b8b8b8;
  height: 2px;
  width: 100%;
  opacity: 0.25;
  margin-right: 1%;
}

.label-dialog-tag {
  font-family: Rubik;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.divi-dialog-tag {
  background-color: #b8b8b8;
  height: 2px;
  opacity: 0.25;
  margin-right: 7%;
  margin-left: 4%;
}

.title-header {
  font-family: Rubik;
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
  padding: 20px;
}

.CircularProgressbarItem {
  width: 50%;
  margin-top: -20%;
}

.CircularProgressbarItem .CircularProgressbarItem-path {
  stroke: #e5156b;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbarItem .CircularProgressbarItem-trail {
  stroke: #d6d6d6;
}

.CircularProgressbarItem .CircularProgressbarItem-text {
  fill: #e5156b;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbarItem .CircularProgressbarItem-background {
  fill: #d6d6d6;
}

.CircularProgressbarItem.CircularProgressbarItem-inverted
  .CircularProgressbarItem-background {
  fill: #e5156b;
}

.CircularProgressbarItem.CircularProgressbarItem-inverted
  .CircularProgressbarItem-text {
  fill: #fff;
}

.CircularProgressbarItem.CircularProgressbarItem-inverted
  .CircularProgressbarItem-path {
  stroke: #fff;
}

.CircularProgressbarItem.CircularProgressbarItem-inverted
  .CircularProgressbarItem-trail {
  stroke: transparent;
}

.CircularProgressbarItemdetermineted {
  margin-left: 15%;
  fill: black;
}

.dropzone-avatar {
  width: 320px;
  margin-left: 20%;
  border-style: none;
  border-radius: 10px;
  height: 147px;
  border-color: #c9c7c7;
}

.dropzone-image {
  width: 100%;
  height: 100%;
  border-color: #c9c7c7;
}

.label-description-canvas {
  height: 13px;
  font-family: Rubik;
  font-size: 13px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

.label-header:hover {
  cursor: pointer;
}

.label-btn-login {
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  pointer-events: auto;
  display: inline-block;
  cursor: pointer;
}

.dropDown-text-btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dropDown-text-btn img {
  width: 30px;
  height: 30px;
  padding-left: 2px;
  border-radius: 8px;
}

.dropDown-text-btn img:hover {
  box-shadow: none;
  background-color: transparent;
}

.short-answer__container {
  height: 100%;
}

/* TODO: refactor when Publication Tags feature get done */
/* span div {
  padding: 0 !important;
} */

.nohover-btnclick {
  box-shadow: none !important;
}

/*
 * react-circular-progressbar styles
 *
 * All of the styles in this file are optional and configurable!
 */

.CircularProgressbar {
  /*
     * This fixes an issue where the CircularProgressbar svg has
     * 0 width inside a "display: flex" container, and thus not visible.
     *
     * If you're not using "display: flex", you can remove this style.
     */
  width: 50%;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #e5156b;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #e5156b;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/*
   * Sample background styles. Use these with e.g.:
   *
   *   <CircularProgressbar
   *     className="CircularProgressbar-inverted"
   *     background
   *     percentage={50}
   *   />
   */
.CircularProgressbar.CircularProgressbar-inverted
  .CircularProgressbar-background {
  fill: #e5156b;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

.CircularProgressbardetermineted {
  margin-left: 15%;
  fill: black;
}

.alert-dialog-body {
  border-radius: 5px;
  height: 100%;
  border-top: 8px solid #e5156b;
  background-color: #ffffff;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.label-dialog {
  height: 60px;
  font-family: Rubik;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}

.submessage-dialog {
  color: rgba(123, 123, 123, 1);
  font-size: 16px;
  width: 80%;
  text-align: center;
}

.btn-cancel {
  width: 110px;
  height: 40px;
  border-radius: 7px;
  border: solid 2px #999999;
  background-color: #ffffff;
  opacity: 0.5;
  margin-right: 23px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
}
.btn-cancel:hover {
  cursor: pointer;
}

.btn-confirm {
  width: 110px;
  height: 40px;
  border-radius: 7px;
  background-image: linear-gradient(to right, #e5156b, #e5156b);
  border: none;
  margin-top: 50px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.btn-confirm:hover {
  cursor: pointer;
}
.dialog-yes-no-body {
  margin-top: 40px;
}

.text-descri-tag {
  margin-top: 16px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
}
.edit-tag {
  color: #403f4c;
}

.body-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.main-dialog {
  /* background: rosybrown; */
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.buttons-dialog {
  /* background: red; */
  width: 70%;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-copy-address {
  margin-top: 0 !important;
  width: 180px !important;
}

.user-address {
  margin-left: 3px;
  /* background: green; */
}

.user-address,
.user-name,
.user-phoneNumber {
  /* background: rebeccapurple; */
  display: flex;
  justify-content: flex-start;
  min-width: 250px;
  max-width: 400px;
}

.span-user-name,
.span-user-phoneNumber {
  margin-left: 10px;
}

.span-user-address {
  margin-left: 15px;
  text-align: left !important;
}

.row-header-term {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2%;
}

.term-header {
  font-family: Rubik;
  font-size: 28.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.Select-menu-outer {
  z-index: 100 !important;
}
.error-label-input {
  position: relative;
  bottom: 15px;
  font-size: 12px;
  line-height: 12px;
  color: rgb(244, 67, 54);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.drop-zone-area:hover {
  cursor: pointer;
}

.drop-preview {
  margin: 0;
  padding: 0;
  width: 200px;
  height: 40px;
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
  text-align: center;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.drop-preview p {
  padding: 0;
  font-size: 18px;
  color: #3b3b3b;
  margin-top: 15px;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.preview-cover {
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.preview-cover img {
  max-width: 100%;
  max-height: 100%;
}

.preview-cover-movie {
  width: 100%;
}

.preview-cover-movie .video-react.video-react-fluid {
  padding-top: 0 !important;
  height: 100%;
  background-color: transparent;
}

.preview-cover .video-react-has-started .video-react-control-bar {
  position: relative;
}

.image-cover-container {
  margin: 0;
  padding: 0;
  width: 510px;
  min-height: 170px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
}

.image-cover-default {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.image-cover-default p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #505050;
  margin-left: 10px;
  line-height: 24px;
  font-style: normal;
  font-family: Rubik;
}

.image-cover img {
  max-width: 510px;
  max-height: 500px;
  cursor: pointer;
  border-radius: 8px;
}

.react-select-container {
  width: 100% !important;
  height: 100% !important;
}

.dropdown-indicator-new {
  width: 15px;
  height: 15px;
  margin-left: -24px;
  z-index: 10;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  position: relative;
  text-align: center;
}

.clear-indicator-new {
  width: 23px;
  height: 23px;
  font-size: 18px;
  z-index: 15;
  cursor: pointer;
  text-align: center;
  margin-right: 20px;
  padding: 0 !important;

  position: relative;
  margin-top: auto;
  margin-bottom: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.clear-indicator-new:hover {
  background-color: #ffbdad;
}

.clear-indicator-new > svg > path {
  stroke: red;
}

.dropdown-indicator-new:hover > svg > path {
  stroke: #696969;
}

.info-body-cover {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 44px 50px 35px 50px;
}

.header-comments {
  border-top: 1px, solid, #e5156b;
  height: 5px;
  background-color: #e5156b;
  border-radius: 5px 5px 0 0;
}

.header-row-comments {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1.5px #cccccc;
  height: 15%;
}

.header-row-exit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-row-report-header:hover {
  cursor: pointer;
}

.header-row-exit:hover {
  cursor: pointer;
}

.label-comments {
  font-family: Rubik;
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #333333;
  margin-top: 1%;
}

.img-exit {
  width: 5px;
  height: 5px;
  object-fit: contain;
}

.body-comments {
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  height: 80%;
}

.row-comments {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 5%;
  margin-left: 5%;
}

.user-name-comments {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d2d2d;
}

.comments-timer {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #999999;
}

.col-comments {
}

.comment-area {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: flex-start;
}

.comments-text {
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  margin-top: 5px;
}

.remover-comment-label {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.remover-comment-label:hover {
  cursor: pointer;
  text-decoration: underline;
}

.col-row-comments {
  width: 70%;
  display: flex;
  flex-flow: column;
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.field-search {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.remover-comment-label-confirm {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.form-report {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 500px;
  padding: 20px;
}

.header-row-report {
  display: flex;
  align-items: center;
  flex-flow: column;
}

.label-desc-report {
  width: 400px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #666666;
}

.display-flex-toogle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  text-align: left;
  opacity: 0.53;
}

.nav-header-container {
  height: 100.4px;
  padding: 0 16px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  align-items: center;
}

ul {
  list-style-type: none;
}

.ul-actions-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  padding-left: 0px;
}
.logo-menu {
  width: 100px;
  height: 100px;
}
.menu-item {
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  opacity: 0.5;
  color: #acacac;
  cursor: pointer;
}
.menu-item-select {
  border-bottom: 2.1px #e5156b solid !important;
  cursor: pointer;
  opacity: 1;
  color: #403f4c;
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
}

.menu-item:hover {
  border-bottom: #e5156b !important;
  border-bottom: 2.1px #e5156b solid !important;
  cursor: pointer;
  opacity: 1;
  height: 100%;
  color: #403f4c;
}
.ul-actions-user-config {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20%;
}


.ul-sub-nav {
  list-style-type: none;
  display: flex;
  align-items: center;
  width: 50%;
}

.ul-sub-nav-actions {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}

.wrap-list {
  display: flex;
}

.menu-item-secondary {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
  height: 100%;
}
.menu-item-secondary:hover {
  opacity: 0.3;
  cursor: pointer;
  border-bottom: 1px solid #403f4c;
}

.header-second-border {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.notification-container {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  -webkit-user-select: none;
          user-select: none;
}

.notification-container label {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(125, 134, 161, 1);
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
}
.notification-container input {
  visibility: hidden;
}

.checkmark {
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 2px;
  position: relative;
}

.notification-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.notification-container input:checked ~ .checkmark {
  background-color: #3477eb;
}

.notification-container .checkmark:after {
  content: '';
  display: none;
  position: absolute;
  left: 7px;
  bottom: 6px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.notification-container input:checked ~ .checkmark:after {
  display: block;
}

.ul-sub-nav span {
  font-size: 12px;
  color: rgba(80, 80, 80, 0.5);
  font-family: 'Rubik', sans-serif;
  font-weight: 300;
  margin-left: 20px;
}

.label-tags {
  height: 14px;
  opacity: 0.53;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: left;
  color: #616161;
}
.label-email {
  height: 12px;
  opacity: 0.53;
  font-family: Rubik;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: left;
  color: #616161;
}

.row-speaker-colum {
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
}

.auto-complete-tags-user .error-label-input {
  bottom: 90px;
}

.table-select {
  border-radius: 5.3px !important;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
  margin-top: 2%;
  padding-left: 20px;
}

.th-head {
  height: 70px;
  border-bottom: 1px solid #eeeeee;
}

.th-head .main-checkbox > div {
  margin-left: 69px !important;
}

.labels-headers {
  opacity: 0.61;
  font-family: Rubik;
  font-size: 12.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-right: 8px;
  padding-left: 15px;
}
.labels-headers:last-of-type .select-item-header {
  padding-left: 0;
  justify-content: center;
}

.labels-headers:hover {
  cursor: auto !important;
  color: #333333;
}
.labels-headers-marked {
  font-family: Rubik;
  font-size: 18.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-right: 8px;
}
.labels-headers-marked:hover {
  cursor: pointer;
  color: #333333;
}

.table-how-selectable {
  height: 70.8px;
  border-radius: 5.3px;
  background-color: #ffffff;
}
.table-how-selectable:hover {
  border: solid 1.3px rgba(11, 11, 11, 0.68);
  cursor: pointer;
}

.table-body-selectable .table-how-selectable {
  display: flex;
  align-items: center;
}

.ic-order {
  width: 8px;
  height: 8px;
  margin-left: 8px;
}

.select-item-header {
  display: flex;
  align-items: center;
  text-align: center;
}

.check-and-photo-area {
  display: flex;
  align-items: center;
}

.checkBox {
  width: 21px;
  height: 21px;
  border-radius: 3px;
  border: solid 1.5px #979797;
  background-color: #ffffff !important;
  color: #ffffff !important;
}

.main-items {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.secondary-items {
  align-items: center;
}

.th-row {
  align-items: center;
  height: 60px;
  border-radius: 5px !important;
}
.th-row:hover {
  background-color: #f8f8f8;
}

.table th,
.table td {
  vertical-align: middle !important;
  border-top: none;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle !important;
  border-top: none;
}

td {
  font-family: Rubik;
  font-size: 13.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.email {
  display: flex;
  flex-direction: column;
  opacity: 0.5;
}

.th-heade {
  height: 50px;
  border: solid;
}

.teste {
  border-radius: 5px;
}

.dialog-page {
  opacity: 0.57;
  -webkit-filter: blur(10);
          filter: blur(10);
  background-color: #ffffff;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.body-dialog-table {
  width: 50%;
  height: 391px;
  border-radius: 4px;
  position: relative;
  z-index: 99000;
  top: 0;
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.22);
  background-color: black;
  border: solid 1.2px #dcdcdc;
}

.display-dialog {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label-alert {
  font-family: Rubik;
  font-size: 22.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3d3737;
}

.Modal {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 20px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  width: 60%;
  height: 60%;
  z-index: 50000000;
}

.confirm-btn-resend-token {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-users-length {
  font-family: Rubik;
  font-size: 18.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}
.row-users {
  display: flex;
  padding-top: 7%;
  margin-bottom: 5%;
  flex-direction: row;
  justify-content: space-between;
}
.display-chips {
  display: flex;
  padding-right: 10px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.body-form-score {
  padding: 10px;
}
.form-to-score {
  display: flex;
  flex-flow: column;
  width: 70%;
  margin-top: 5%;
}
.submit-btn {
  position: relative;
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-exit {
  width: 15px;
  height: 15px;
}

.btn-exit:hover {
  cursor: pointer;
}

.btn-remove-chip {
  width: 10px;
  height: 10px;
  margin-left: 10px;
  padding-left: 0;
}

.btn-remove-chip:hover {
  cursor: pointer;
}

.chip-body {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

::scrollbar {
  width: 10px;
  background-color: #ffffff;
}

::-webkit-scrollbar {
  width: 20px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgb(252, 249, 249);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dfdfdf;
}

.body-comfirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40%;
}

.body-form-score-comfirm {
  height: 100%;
  margin-top: 50%;
}

.label-comfirm-main {
  font-family: Rubik;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}
.label-comfirm-secondary {
  opacity: 0.72;
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}

.row-exit-confirm {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.body-form-tag-insert {
  width: 500px;
  height: 400px;
}

.place-holder-body {
  display: flex;
  align-items: center;
  width: 100%;
  height: 300px;
  justify-content: space-around;
}

.label-place-holder {
  font-family: Rubik;
  font-size: 36.4px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}

.publication-list-dash {
  height: 450px;
  border-radius: 4.5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border: solid 1.5px #e6e6e6;
  width: 60%;
  margin-bottom: 50px;
}

.header-publication-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 25px;
  margin-top: 20px;
  height: 50px;
}

.main-label-header-publication-list {
  font-family: Rubik;
  font-size: 16.8px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #333333;
}

.secondary-label-header-publication-list {
  font-family: Rubik;
  font-size: 14.1px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #999999;
}

.header-publication-list-description {
  display: flex;
  flex-flow: column;
}

.label-title-list {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.label-title-secondary {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

.label-title-secondary-action {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

.label-title-secondary-action:hover {
  cursor: pointer;
  border-bottom: #acacac, solid, 1px;
}

.ico-publication-list {
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-left: 10px;
}

.post-atributes-list {
  display: flex;
  align-items: center;
}

.item-list-post {
  width: 50px;
}

.header-interaction-row-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;
}

.element-header-interaction {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 25%;
}

.main-label-interaction {
  font-family: Rubik;
  font-size: 42.7px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}
.secondary-label-interaction {
  font-size: 18.4px;
  color: #999999;
  font-family: Rubik;
  font-size: 16.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
}
.porc-label-positive {
  font-family: Rubik;
  font-size: 16.1px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #38d486;
}
.porc-label-negative {
  font-family: Rubik;
  font-size: 19.1px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #d23155;
}
.border-right-element {
  width: 1px;
  height: 79px;
  opacity: 0.59;
  background-color: #979797;
}

.ic-porc-interaction {
  width: 18px;
  height: 18px;
  margin-right: 15px;
}

.row-porc {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.header-chart-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px 50px;
}
.header-chart-publication-description {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.main-label-chart-publication {
  font-family: Rubik;
  font-size: 24.8px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}
.secondary-label-chart-publication {
  font-family: Rubik;
  font-size: 16.1px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #999999;
}

.linear-progress-publication {
  height: 50px;
  margin-bottom: 20px;
  margin-right: 7%;
  margin-left: 7%;
}

.tag-publication-body {
  width: 38%;
  height: 450px;
  border-radius: 4.5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border: solid 1.5px #e6e6e6;
  margin-bottom: 50px;
  padding: 15px;
}

.header-tag-dash {
  display: flex;
  align-items: flex-start;
  flex-flow: column;
}

.main-label-tag-dash {
  font-family: Rubik;
  font-size: 18.8px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}

.secondary-label-tag-dash {
  font-family: Rubik;
  font-size: 16.1px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #999999;
}

.header-painel-controller {
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
  height: 50px;
}

.header-controller-list {
  display: flex;
  list-style-type: none;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

.main-label-painel {
  font-family: Rubik;
  font-size: 45.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}

.action-label-painel {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #999999;
  margin-left: 20px;
}

.action-label-painel-selected {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #999999;
  margin-left: 20px;
  border-bottom: solid 2px #e5156b;
  cursor: pointer;
}

.action-label-painel:hover {
  border-bottom: solid 2px #e5156b;
  cursor: pointer;
}

.body-user-list-dash {
  width: 49%;
  height: 366px;
  border-radius: 4.5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border: solid 1.5px #e6e6e6;
  padding-top: 20px;
}

.main-label-user-engage {
  font-family: Rubik;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-left: 20px;
}

.header-user-list {
  height: 40px;
}

.label-score-dash {
  font-family: Rubik;
  font-size: 16px;
  width: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: right;
  margin-right: 8px;
  margin-top: 6px;
  color: #da9c00;
}

.label-user-score-dash {
  font-family: Rubik;
  font-size: 16px;
  width: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: right;
  margin-right: 8px;
  margin-top: 6px;
  color: black;
}

.label-name-user {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-left: 10px;
}

.flex-avatar-name {
  display: flex;
  align-items: center;
}
.flex-avatar-img {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
}

.speaker-card-body {
  width: 30%;
  height: 80px;
  border-radius: 4.5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border: solid 1.5px #e6e6e6;
  margin-bottom: 50px;
}

.device-card-body {
  width: 62%;
  height: 100px;
  border-radius: 4.5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border: solid 1.5px #e6e6e6;
  margin-bottom: 50px;
}

.label-name-speaker {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #333333;
}

.main-label-speaker {
  font-family: Rubik;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-left: 20px;
}

.speaker-display-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.label-score-dash-user {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: right;
  color: #da9c00;
}

.speaker-row {
  display: flex;
  align-items: left;
  flex-flow: column;
}

.speaker-label {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
  margin-top: -10px;
}

.row-devices-porc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.row-device-progress {
  margin-left: 20px;
  margin-right: 20px;
}

.label-devices {
  font-family: Rubik;
  font-size: 17.9px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3b3b3b;
}

.body-list-product {
  height: 466px;
  width: 65%;
  border-radius: 4.5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border: solid 1.5px #e6e6e6;
  width: 55%;
  margin-bottom: 50px;
}

.body-card-product-list-rigth {
  height: 400px;
  width: 30%;
  border-radius: 4.5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border: solid 1.5px #e6e6e6;

  margin-bottom: 50px;
}

.header-controll {
  margin-left: 84px;
  margin-right: 84px;
  height: 67px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
}

.header-control-container {
  height: 67px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.body-chart-header {
  height: 67px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.label-value-total-engagemente {
  font-family: Rubik;
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.label-value-total-engagemente-text {
  height: 36px;
  font-family: Rubik;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
  margin-top: 12px;
  margin-left: 20px;
}

.label-value-total-engagemente-media {
  font-family: Rubik;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f22424;
}
.label-value-total-engagemente-up {
  height: 36px;
  font-family: Rubik;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1dbf7a;
  margin-top: 20px;
}

.text-interval {
  width: 193px;
  height: 24px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
  margin-top: 16px;
  margin-left: 5px;
}
.text-interval-number {
  font-size: 24px;
  margin-top: 16px;
  color: #3fa949;
}
.text-interval-porcentagem {
  font-size: 20px;
  margin-top: 16px;
  color: #3fa949;
}

.Intervalo-limite-Ab {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}
.text-style-1 {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: right;
  margin-top: 16px;
  margin-left: 1px;
  color: #403f4c;
}

.card-chart {
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.card-list-body {
  height: 218px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-right: 2%;
  width: 70%;
}

.card-tag-body {
  height: 218px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  width: 29%;
}

.card-list-body-drop {
  height: 240px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-right: 1%;
  width: 35%;
}

.card-list-divisori {
  width: 95%;
  height: 2px;
  opacity: 0.36;
  background: #979797;
  margin-left: 10px;
}

.title-header-card-list {
  padding-top: 30px;
  padding-left: 5px;
  padding-bottom: 20px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.title-header-with-dropbox {
  padding-top: 0px;
  padding-left: 5px;
  padding-bottom: -5px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}
.title-header-card-simple {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 2px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.header-options {
  height: 12px;
  margin-left: 20px;
  padding-top: 30px;
  padding-bottom: 10px;
  opacity: 0.52;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #403f4c;
}

.header-options:hover {
  border-bottom: #e5156b !important;
  border-bottom: 2.1px #e5156b solid !important;
  cursor: pointer;
}

.img-icon {
  height: 30px;
  padding-left: 10px;
}
.publicatio-title {
  height: 17px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.publication-description {
  width: 320px;
  height: 14px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

/*
 * react-circular-progressbar styles
 *
 * All of the styles in this file are optional and configurable!
 */

.CircularProgressbarItem {
  /*
   * This fixes an issue where the CircularProgressbarItem svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   *
   * If you're not using "display: flex", you can remove this style.
   */
  width: 109px;
}

.CircularProgressbarItem .CircularProgressbarItem-path {
  stroke: #e5156b;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbarItem .CircularProgressbarItem-trail {
  stroke: #d6d6d6;
}

.CircularProgressbarItem .CircularProgressbarItem-text {
  fill: #e5156b;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbarItem .CircularProgressbarItem-background {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbarItem
 *     className="CircularProgressbarItem-inverted"
 *     background
 *     percentage={50}
 *   />
 */
.CircularProgressbarItem.CircularProgressbarItem-inverted
  .CircularProgressbarItem-background {
  fill: #e5156b;
}

.CircularProgressbarItem.CircularProgressbarItem-inverted
  .CircularProgressbarItem-text {
  fill: #fff;
}

.CircularProgressbarItem.CircularProgressbarItem-inverted
  .CircularProgressbarItem-path {
  stroke: #fff;
}

.CircularProgressbarItem.CircularProgressbarItem-inverted
  .CircularProgressbarItem-trail {
  stroke: transparent;
}

.CircularProgressbarItemdetermineted {
  fill: black;
}

.best-tag-user {
  margin-top: 10px;

  height: 20px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e5156b;
}
.tag-normal {
  width: 19px;
  height: 20px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #616161;
}

.tag-used {
  height: 12px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
  margin-bottom: 15px;
}

.chart-header {
  height: 17px;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.6;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.likes-legend {
  height: 14px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #e5156b;
}

.views-legend {
  height: 14px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #616161;
}
.comments-legend {
  height: 14px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #1bab60;
}

.circulo-views {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
  background-color: #616161;
  margin-left: 5px;
}

.circulo-comments {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
  background-color: #1bab60;
}
.circulo-likes {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
  background-color: #e5156b;
}

.device-ios {
  width: 76px;
  height: 14px;
  font-family: Rubik;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e5156b;
}

.device-android {
  width: 76px;
  height: 14px;
  font-family: Rubik;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
}
.label-score {
  height: 17px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: right;
  color: #da9c00;
}

.number-user-score {
  width: 15px;
  height: 14px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #acacac;
}

.div-dash {
  width: 100%;
  margin-top: 2px;
  height: 1px;
  opacity: 0.45;
  border: solid 1px #979797;
}

.simple-card {
  height: 137px;
  margin-top: 10px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.label-holder {
  font-family: Rubik;
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.43;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}
.label-avarage {
  font-family: Rubik;
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.43;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.label-drop-down {
  height: 17px;
  font-family: Rubik;
  margin-left: -25px;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.67;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

.place-holder-text {
  height: 17px;
  opacity: 0.63;
  margin-top: 10%;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.67;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.load-graphic {
  margin-top: 150px;
  margin-bottom: 100px;
  margin-left: 50%;
}

.teste {
  margin-left: 50px;
  margin-right: 20px;
  width: 400px;
  height: 400px;
}
.ico-down {
  height: 9px;
  width: 12px;
  margin-right: 5px;
}

.img-content {
  margin-left: 84px;
}

.label-avarage-text {
  margin-top: 25px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.43;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.title-body-list-post {
  display: flex;
  align-content: center;
  width: 50%;
  margin-left: 2%;
  margin-top: 5px;
}

.info-body-list-post {
  margin-top: 10px;
  display: flex;
  align-content: flex-end;
  padding-left: 40%;
}

.row-post-and-tags {
  display: flex;
  align-items: center;
}

.body-card-tag {
  display: flex;
  justify-content: space-around;
  height: 100%;
}

.col-tags-card {
  display: flex;
  flex-flow: column;
}
.header-section {
  display: flex;
  align-items: center;
  height: 50px;
}
.card-course-body {
  margin-left: 1%;
  width: 35%;
}

.body-users-cards {
  display: flex;
  align-items: center;
}
.container-simple-card-col {
  display: flex;
  flex-flow: column;
  margin-left: 1%;
  width: 29%;
}

.card-product-list {
  height: 218px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  width: 40%;
  margin-right: 1%;
}

.body-cards-products {
  display: flex;
}

.body-card-average {
  display: flex;
  align-content: center;
  justify-content: space-around;
}

.body-card-average-label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.display-row-home-dash {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.header-row-likes {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1.5px #cccccc;
  height: 15%;
}

.header-row-exit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-row-report-header:hover {
  cursor: pointer;
}

.header-row-exit:hover {
  cursor: pointer;
}

.label-likes {
  font-family: Rubik;
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #333333;
  margin-top: 1%;
}

.img-exit {
  width: 5px;
  height: 5px;
  object-fit: contain;
}

.body-likes {
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  height: 80%;
}

.row-likes {
  display: flex;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 5%;
  margin-left: 5%;

  grid-gap: 22px;

  gap: 22px;
}

.user-name-likes {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d2d2d;
}

.col-likes {
}

.col-row-likes {
  width: 70%;
  display: flex;
  flex-flow: column;
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.field-search {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.header-row-views {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1.5px #cccccc;
  height: 15%;
}

.header-row-exit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-row-report-header:hover {
  cursor: pointer;
}

.header-row-exit:hover {
  cursor: pointer;
}

.label-views {
  font-family: Rubik;
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #333333;
  margin-top: 1%;
}

.img-exit {
  width: 5px;
  height: 5px;
  object-fit: contain;
}

.body-views {
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  height: 80%;
}

.row-views {
  display: flex;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 5%;
  margin-left: 5%;

  grid-gap: 22px;

  gap: 22px;
}

.user-name-views {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d2d2d;
}

.col-views {
}

.col-row-views {
  width: 70%;
  display: flex;
  flex-flow: column;
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.field-search {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.container-segmentation-tag {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
}

.header-segmentation-tag {
  width: 100%;
  height: 30px;
  display: flex;
  text-align: center;
  align-items: center;
  margin: 16px 0px 10px;
  justify-content: center;
}

.header-segmentation-tag h2 {
  margin: 0;
  padding: 0;
  height: 20px;
  display: flex;
  font-size: 18px;
  color: #3b3b3b;
  font-style: normal;
  text-align: center;
  line-height: normal;
  font-weight: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.segmentation-btn {
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  font-family: Rubik;

  border: none;
  color: white;
  cursor: pointer;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.segmentation-btn:focus {
  outline: 3px #eee;
}

.container-selection-tags {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 10px;
  align-items: center;
  justify-content: flex-start;
}

.selection-tags-canvas-publish {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.add-tag-btn-segmentation {
  padding: 0;
  width: 100px;
  height: 26px;
  margin: 0px 8px 8px;

  border: none;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  line-height: normal;
  font-weight: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.5s;
}

.add-tag-btn-segmentation:hover {
  opacity: 0.8;
}

.view-segmentation-tag {
  width: 100%;
  display: flex;
  align-items: center;
}

.segmentation-tag-content {
  display: flex;
  align-items: center;
}

.segmentation-tag-content img {
  width: auto;
  height: 100%;
  margin: 0px 5px;
  border-radius: 8px;
}

.segmentation-tag-content img:hover {
  cursor: default;
  box-shadow: none;
  border-radius: 8px;
  background-color: transparent;
}

.removed-tag-and-expression {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.remove-tag-btn-segmentation {
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;

  border: none;
  cursor: pointer;
  margin-bottom: 8px;
  padding-bottom: 2px;
  line-height: normal;
  font-weight: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
  background-color: transparent;
  border-bottom: 1px solid #d8d8d8;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.5s;
}

.remove-tag-btn-segmentation:hover {
  background-color: #eee;
}

.text-field {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
}
.input:focus {
  outline: none !important;
  border: 1px solid red;
  box-shadow: 0 0 10px #719ece;
}
.form-create-publication {
  padding: 20px;
}
.container-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 120px;
}
.container-row-first {
  display: flex;
  align-items: flex-end;
}

.button-action-clear {
  margin-bottom: 8px;
}

.btn-click:hover {
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.11);
  font-size: 12px;
  cursor: pointer;
}
.tags {
  width: 190px;
  display: flex;
}

.tags > div > span {
  font-size: 12px !important;
}

.container-filter-type {
  margin-right: 15px;
}

.font-text-table{
  font-size: 12.7px !important;
}

.body-form {
  opacity: 0.9;
  padding: 20px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.body-form-submit {
  width: 70%;
  display: flex;
  padding: 20px;
  margin-top: 10px;
  min-height: 220px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.label-canvas {
  height: 18px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
  margin-bottom: 10px;
  margin-top: 10%;
}

.label-canvas-secondary {
  height: 18px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
  margin-bottom: 10px;
  margin-top: 16px;
}

.img-next {
  position: absolute;
  margin-top: 40%;
  margin-left: 0px;
  background-color: #ffffff;
  border: none;
  border-color: #ffffff;
  height: 27px;
  width: 14px;
}

.img-next:hover {
  cursor: pointer;
}

.label-canvas-bottom {
  height: 14px;
  opacity: 0.53;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
  margin-top: 20px;
}

.img-next-btn {
  width: 5%;
  right: 5%;
  position: absolute;
}

.form-date-cover {
  width: 80%;
  display: flex;
}

.img-create {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pontuation-form {
  position: relative;
  width: 300px;
  margin: 0 10px 0 0;
}

.cancel {
  position: absolute;
  width: 10px;
  height: 10px;
}

.cancel > img {
  position: relative;
  margin-left: 262px;
  margin-top: 42px;
  cursor: pointer;
}

.form-choice-send {
  margin: 0;
  margin-left: -35px;
  padding: 0;
  width: 300px;
  height: 100px;
}

.cover-img-publish {
  /* width: 200px; */
  height: auto;
}

.cover-img-publish-create-post .preview-cover-movie {
  height: 100% !important;
}

.cover-img-publish-create-post,
.video-react-control-bar {
  top: 240%;
}

span.note-schedule {
  height: 14px;
  opacity: 0.53;
  font-family: Rubik;
  font-size: 12px;
  text-align: center;
  color: #3b3b3b;
  margin-top: 20px;
}

.details-publish {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .col-md-6{
  background-color: red;
} */

.select-component-body {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  height: 50px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.label-title-component {
  font-family: Rubik;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
  margin-top: 40%;
}

.select-component-fixed-topo {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 20px;
  position: fixed;
  top: 10px;
  right: 11%;
  height: 46px;
  width: 400px;
}

.image-title-component {
  margin-top: 10px;
  margin-left: 40%;
}

.body-select-component {
  border-right: solid 1px #d8d8d8;
  height: 46px;
}
.body-select-component:hover {
  cursor: pointer;
  background-color: #ededee;
}

.label-title-component:hover {
  opacity: 0.3;
  cursor: pointer;
}

.label-dialog-main {
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a6a6a6;
  width: 70%;
  margin-left: 1%;
}

.label-dialog-main-select {
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.label-dialog-main-select:hover {
  cursor: pointer;
  border-bottom: 1px solid #3b3b3b;
}

.label-carousel {
  opacity: 0.45;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.label-dialog-main-select:hover {
  cursor: pointer;
  opacity: 1;
}

.exit-dialog {
  font-size: 36px;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
  background-color: #ffffff;
}
.exit-dialog-text {
  font-size: 36px;
  cursor: pointer;
}

.exit-dialog-text:hover {
  cursor: pointer;
  opacity: 0.5;
}

.body-dialog-row {
  height: 7px;
  border-top: 5px solid #ffff;
  border-radius: 5px 3px 3px 10px;
}
.body-dialog-row:hover {
  cursor: pointer;
}

.dropzone-class:hover {
  cursor: pointer;
}
.img-button-click {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border: none;
  margin-right: 3%;
}
.img-button-click:hover {
  cursor: pointer;
  opacity: 0.5;
}

.erro-images {
  text-align: center;
  opacity: 0.45;
  font-family: Rubik;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 30px;
}

.dropzone-class {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border: none;
}

.dropzone-class-border {
  border: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border: none;
}

.body-row-exit {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}
.body-row-exit:hover {
  cursor: pointer;
  opacity: 0.3;
}

.form-link-class {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 3%;
}

.form-link-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: -10%;
  margin-right: 3%;
}

.modal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 40px;
}

.modal_title {
  font-size: 22px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b4e5c;
  margin-bottom: 16px;
}

.modal_container_select {
  display: flex;
  grid-gap: 25px;
  gap: 25px;
}

.modal_select {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.modal_select_label {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b4e5c;
}

.modal_link_input {
  width: 98%;
  height: 48px;
  border-radius: 4px;
  background: #e7ebf4;
  border: none;
  color: #2e63fb;
  padding: 5px 5px 5px 15px;

  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: start;
}

.modal_link_input::-webkit-input-placeholder {
  color: #2e63fb;
}

.modal_link_input::placeholder {
  color: #2e63fb;
}

.modal_link_input:focus {
  outline: none;
}

.modal_container_buttons {
  display: flex;
  grid-gap: 25px;
  gap: 25px;
}

.dropzone_container {
  max-width: 400px;
  max-height: 100px;
  width: 100%;
}

.dropzone_container:hover {
  cursor: pointer;
}

.dropzone_content {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  border: none;
  background: #e7ebf4;
  height: 100px;
}

.dropzone_container .dropzone_content .dropzone_text {
  margin: 0;
  color: #2e63fb;
}

.dropzone_container .dropzone_content .dropzone_text .dropzone_mark_text {
  text-decoration: underline;
  color: #2e63fb;
}

.header-content-text-segmentation {
  font-size: 22px;
  font-weight: 600;
  color: #2f313d;
  line-height: 32px;
  font-style: normal;
  text-align: center;
  font-family: Rubik;
  margin: 10px 0px 5px 0px;
}

.footer-segmentation-content {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer-segmentation-content img {
  margin: 0px 5px;
  border-radius: 8px;
}

.footer-segmentation-content img:hover {
  cursor: default;
  box-shadow: none;
  border-radius: 8px;
  background-color: transparent;
}

.tag-segmentation-content-modal {
  width: auto;
  height: auto;
  display: flex;
  color: white;
  font-size: 13px;
  padding: 2px 8px;
  border-radius: 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.content-segmentation-tag {
  margin: 0;
  padding: 0;
  width: 100%;
  padding: 25px 0px;
  border-bottom: 0.5px solid #ccd2e0;
}

.last-footer-segmentation {
  border: none;
}

.content-segmentation-tag h4 {
  font-size: 22px;
  font-weight: 600;
  color: #2f313d;
  line-height: 32px;
  font-style: normal;
  font-family: Rubik;
}

.content-segmentation-tag p {
  padding: 0;
  margin-bottom: 16px;
}

.container-illustration-segmentation-tag {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 8px;
  align-items: center;
  background: #f5f7fa;
  justify-content: space-evenly;
}

.circle-segmentation {
  width: 88px;
  height: 88px;
  display: flex;
  font-size: 14px;
  color: #2f313d;
  text-align: center;
  align-items: center;
  border-radius: 100%;
  mix-blend-mode: normal;
  justify-content: center;
  background-color: rgba(204, 210, 224, 0.35);
}

.segmentation-tag-intercession {
  justify-content: center;
}

.segmentation-tag-intercession .circle-segmentation {
  width: 96px;
  height: 96px;
}

.segmentation-tag-intercession .circle-segmentation:nth-child(1) {
  margin-right: -10px;
}

.segmentation-tag-intercession .circle-segmentation:nth-child(2) {
  margin-left: -10px;
}

.segmentation-tag-subtraction {
  justify-content: center;
}

.segmentation-tag-subtraction .circle-segmentation {
  width: 113px;
  height: 113px;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.segmentation-tag-subtraction .circle-segmentation div {
  width: 40px;
  height: 40px;
  margin: 30px 30px 0px 0px;
  background-color: rgba(204, 210, 224, 1);
}

:root {
  --default-font: Rubik, sans-serif;
}

.header-row {
  border-bottom: solid 1px #d8d8d8;
}

.btn-add-component {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  height: 50px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.btn-add-component:hover {
  cursor: pointer;
}

.btn-add-component-label {
  opacity: 0.85;
  font-family: Rubik, sans-serif;
  font-family: var(--default-font);
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
  margin-top: 15px;
}

.btn-add-component-fixed {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 20px;
  position: fixed;
  top: 10px;
  right: 11%;
  height: 46px;
  width: 400px;
}

.hide-select-components {
  display: none;
}
.select-components-show {
  display: initial;
}

.teste_hide {
  display: none;
}
.teste_show {
  display: initial;
}

.header-segmentation-tag-edit {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.header-segmentation-tag-edit h2 {
  margin: 0;
  padding: 0;
  opacity: 0.85;
  font-size: 22px;
  color: #3b3b3b;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  line-height: normal;
  padding-bottom: 25px;
  font-stretch: normal;
  font-family: Rubik, sans-serif;
  font-family: var(--default-font);
  letter-spacing: normal;
}

.header-segmentation-tag-field {
  display: flex;
  align-items: center;
}

.header-segmentation-tag-field > div {
  flex-grow: 1;
}

.tooltip {
  font-family: Rubik, sans-serif;
  font-family: var(--default-font);
  font-size: 12.8px;
  text-align: center;
  padding: 5px !important;
  height: 24.9px;
  color: #000000;
  border-radius: 20px !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
}

.container-edit-tags-segmentation {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.special-post-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.special-post__field-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.special-post__label {
  cursor: pointer;
  font-family: Rubik, sans-serif;
  font-family: var(--default-font);
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.55;
  padding-bottom: 2px;
}

.special-post__label:hover {
  padding-bottom: 0px;
  border-bottom: 2px solid;
}

.cancel-edit {
  position: absolute;
  width: 10px;
  height: 10px;
}

.cancel-edit > img {
  position: relative;
  margin-left: 125px;
  margin-top: 39px;
  cursor: pointer;
}

.cancel-date > img {
  margin-top: 46px !important;
  z-index: 2;
}

.body-preview {
  width: 96.5%;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-top: 5px solid #d8d8d8;
  position: absolute;
  z-index: -2;
  margin-left: -10px;
}

.title-publication-preview {
  opacity: 0.7;
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
  margin: 20px 0px 20px;
}

.title-publication {
  padding: 0 16px;
  font-family: Rubik;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  word-break: break-word;
  color: #212121;
}

.body-question-preview {
  width: 100%;
  display: flex;
  padding: 0px 15px 0px;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}

.body-question-preview p {
  text-align: left;
}

.text-preview-element {
  padding: 1rem 0;
  word-break: break-word;
}

.text-preview-element p + p {
  margin-top: 1rem;
}

.text-preview-element ul {
  list-style-type: disc;
}

.container-fluid-answer {
  width: 100%;
  padding: 16px;
  border-radius: 20px;
  margin-bottom: 10px;
  background: #f5f7fa;
  text-align: start;
}

.container-fluid-text {
  width: 100%;
  padding: 16px;
  border-radius: 20px;
  margin-bottom: 10px;
  text-align: start;
}

.body-container-form {
  display: flex;
  border-radius: 2px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  border-top: 5px solid #d8d8d8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.img-form-result {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.title-preview-form {
  font-size: 18px;
  font-weight: 600;
  color: #333947;
  line-height: 28px;
  text-align: start;
  font-style: normal;
  font-family: Rubik;
}

.preview-form {
  height: 70%;
  margin-top: 16px;
}

.img-alteration {
  margin-right: 16px;
  height: 18px;
}

.preview-title-container-fluid {
  width: 100%;
  display: flex;
  font-size: 18px;
  line-height: 24px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 16px;
  padding: 20px 0px 16px;
  justify-content: center;
  background-color: #f4f6fb;
}

.input-preview-question {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 8px;
  background: #ffffff;
}

.input-preview-question::-webkit-input-placeholder {
  padding: 16px;
  font-size: 18px;
  font-weight: 300;
  color: #ccd2e0;
  line-height: 24px;
  font-family: Rubik;
  font-style: normal;
}

.input-preview-question::placeholder {
  padding: 16px;
  font-size: 18px;
  font-weight: 300;
  color: #ccd2e0;
  line-height: 24px;
  font-family: Rubik;
  font-style: normal;
}

.preview-container-form {
  width: 33%;
}

.text-details-form {
  font-size: 18px;
  color: #7d86a1;
  font-weight: 300;
  line-height: 24px;
  font-style: normal;
  font-family: Rubik;
}

.header-preview-post {
  width: 105%;
  margin-left: -10px;
  padding-top: 20px;
  padding-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.header-preview-post-content {
  font-family: Rubik;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: 75%;
  padding-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
}

.header-preview-post-content h2 {
  font-size: 18px;
}

.header-preview-post-content span {
  font-size: 12px;
  color: rgba(80, 80, 80, 0.5);
}

.react_player_container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.react_player_container .video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.react-player {
  border-radius: 10px;
  overflow: hidden;
}

.tooltip-link {
  width: 215px !important;
  height: 45px !important;
  background-color: #b84949 !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27) !important;
  color: #444444 !important;
  opacity: 1 !important;
}

.label-acess-link {
  width: 76px;
  height: 14px;
  opacity: 0.63;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #444444;
  opacity: 1 !important;
}

.label-btn-imag {
  opacity: 0.63;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #444444;
}
.label-btn-imag:hover {
  cursor: pointer;
  border-bottom: 1px solid #444444;
}
.extra {
  pointer-events: auto !important;
  width: 215px;
  height: 45px;
  opacity: 1 !important;
  background-color: #ffffff !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27);
  cursor: pointer !important;
}

.btn-edit-image {
  pointer-events: auto !important;
  width: 228px;
  height: 45px;
  opacity: 1 !important;
  background-color: #ffffff !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27);
  cursor: pointer !important;
  position: absolute;
  top: 50%;
  left: 40%;
  display: none;
}

.btn-edit-image-show {
  pointer-events: auto !important;
  width: 228px;
  height: 45px;
  opacity: 1 !important;
  background-color: #ffffff !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27);
  cursor: pointer !important;
  position: absolute;
  top: 50%;
  left: 35%;
  display: inline-block;
  margin-top: 1px;
}
.btn-edit-image-show-carousel {
  pointer-events: auto !important;
  width: 70%;
  height: 7%;
  opacity: 1 !important;
  background-color: #ffffff !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27);
  cursor: pointer !important;
  position: absolute;
  top: 43%;
  left: 15%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 100%;
  justify-content: space-between;
  padding-right: 30px;
  padding-left: 30px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27);
}

.container-tooltip-carousel {
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: 100%;
  justify-content: space-evenly;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27);
}

.extra:hover {
  visibility: visible !important;
  opacity: 1 !important;
  cursor: pointer !important;
}

.label-link {
  width: 76px;
  height: 14px;
  opacity: 0.63;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #444444;
  opacity: 1 !important;
}
.extra_carousel {
  pointer-events: auto !important;
  width: 400px;
  height: 45px;
  background-color: #ffffff !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27);
  opacity: 1 !important;
}
.label-btn-text {
  opacity: 0.63;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #444444;
  text-align: center;
}
.container-tooltip-text {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27);
  width: 100%;
}

.label-btn-text:hover {
  cursor: pointer;
  border-bottom: 1px solid #444444;
}

.title-canvas {
  margin-top: 30px;
  height: 36px;
  font-family: Rubik;
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.form-edit-canvas {
  display: flex;
}
.body-canvas-edit {
  margin-bottom: 5%;
}

.button-schedule {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
  margin-right: 9%;
}

.container-modal-schedule {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.row-exit-schedule {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0 0;
}

.label-title {
  margin: 0 auto;
  font-family: Rubik;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}

.body-schedule {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.body-schedule-confirm {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 80px 0 20px;
}

.label-form {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.label-chindren-date {
  text-align: initial;
}

.label-chindren {
  margin-top: 20px;
  text-align: initial;
}

.label-chindren-button {
  margin: 25px 0 20px;
  align-items: center;
}

.label-confirm-button {
  margin: 50px 0 20px;
  align-items: center;
}

.form-choice {
  width: 100%;
  margin: 40px 20px 20px;
}

.body-first-question {
  display: flex;
  flex-direction: column;
}

.form-questions {
  display: flex;
  flex-direction: column;
}

.form-title-preview {
  width: 100%;
  display: flex;
  margin-top: 10px;
}

.container-draw {
  padding-top: 20px;
}

.ck-content ul {
  list-style-type: disc !important;
}

.ck-content li {
  font-size: 1.5rem;
}

.text-red {
  color: red;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  height: 730px;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide > div {
  width: 100%;
  height: 100%;
}

.carousel .slide {
  background: #e6e6e6 !important;
}

.carousel .slide img {
  height: 100%;
  object-fit: contain;
}

.carousel .thumb {
  width: 80px;
  height: 80px;
}

.carousel .thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.caroussel__btn {
  position: absolute;
  z-index: 2;
  top: calc(50% - 434px);
  width: 30px;
  height: 730px;
  cursor: pointer;

  border: none;
  background-color: rgba(0, 0, 0, 0.3);
  outline: none;

  color: white;
}

.caroussel__btn:focus {
  outline: none;
}

.caroussel__btn--left {
  left: 15px;
}

.caroussel__btn--right {
  right: 15px;
}

.body-preview .carousel .slider-wrapper.axis-horizontal .slider .slide {
  height: 307px;
}

.body-preview .carousel .slider-wrapper.axis-horizontal .slider .slide > div {
  width: 100%;
  height: 100%;
}

.body-preview .carousel .slide {
  background: #e6e6e6;
}

.body-preview .carousel .slide img {
  height: 100%;
  object-fit: contain !important;
}

.body-options-form .carousel .slider-wrapper.axis-horizontal .slider .slide {
  height: 690px;
}

.body-question-preview
  .carousel
  .slider-wrapper.axis-horizontal
  .slider
  .slide {
  height: 307px;
}

.body-question-preview
  .carousel
  .slider-wrapper.axis-horizontal
  .slider
  .slide
  > div {
  width: 100%;
  height: 100%;
}

.body-options-form .caroussel__btn {
  height: 690px;
  top: calc(50% - 413px);
}

.body-question-preview .video-react {
  margin-bottom: 16px;
}

.body-preview .img-form-result,
.body-question-preview .img-form-result {
  margin-bottom: 16px;
}

.title-canvas {
  margin-top: 30px;
  height: 36px;
  font-family: Rubik;
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.form-edit-canvas-body {
  display: flex;
  flex-direction: column;
}

.body-canvas-edit {
  margin-bottom: 5%;
}

.button-schedule {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
  margin-right: 9%;
}

.container-modal-schedule {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.row-exit-schedule {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0 0;
}

.label-title {
  margin: 0 auto;
  font-family: Rubik;
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  color: #3b3b3b;
}

.body-schedule {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.body-schedule-confirm {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 80px 0 20px;
}

.label-form {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.label-chindren-date {
  text-align: initial;
}

.label-chindren {
  margin-top: 20px;
  text-align: initial;
}

.label-chindren-button {
  margin: 25px 0 20px;
  align-items: center;
}

.label-confirm-button {
  margin: 50px 0 20px;
  align-items: center;
}

.form-choice {
  width: 100%;
  margin: 40px 20px 20px;
}

.body-first-question {
  display: flex;
  flex-direction: column;
}

.form-questions {
  display: flex;
  flex-direction: column;
}

.form-title-preview {
  margin: 0;
  padding: 0;
  width: 67%;
  height: 130px;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
}

.body-options-form {
  margin-bottom: 50px;
}

.title-header {
  font-size: 18px;
}

.selected-component-form {
  height: 46px;
  width: 400px;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.body-select-form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ededee;
}

.body-select-form:hover {
  cursor: pointer;
  background-color: #ededee;
}

.body-components-end-date {
  display: flex;
  flex-direction: column;
}

.form-title-question {
  margin: 0;
  font-size: 18px;
  color: #403f4c;
  font-weight: 500;
  text-align: left;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
  padding: 20px 20px 0px;
}

.components-question-title {
  margin-bottom: 20px;
}

.btn-body {
  display: flex;
  cursor: pointer;
  line-height: 20px;
  font-family: Rubik;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  align-items: center;
  letter-spacing: 0.5px;
  justify-content: center;
  text-transform: uppercase;
}

.btn-form-questions {
  margin: 0;
  font-size: 14px;
  padding: 8px 11px 8px;
}

.adc-btn-form-question {
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: bold;
  padding: 4px 0px 6px 11px;
}

.list-questions {
  margin: 0;
  padding: 0;
  /* width: 350px; */
  height: 55px;
  display: flex;
  margin-bottom: 15px;
  align-items: flex-end;
}

.body-question {
  width: 350px;
  margin: 10px 0px 10px;
}

.form-btn-body {
  display: flex;
  margin: 0px 10px;
  justify-content: flex-start;
}

.tooltip-remove-text-form {
  width: 100%;
  display: flex;
  position: relative;
}

.body-form-details {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
}

.form-sweepstakes {
  display: flex;
  flex-direction: column;
}

.form-sweepstakes label {
  height: 100px;
}

.date-sweepstakes label {
  width: 210px;
}

.qtd-sweepstakes {
  width: 360px;
}

.rules-sweepstakes {
  width: 205px;
}

.body-definitions {
  margin-top: 100px;
  border-top: solid 1px #d8d8d8;
}

.container-sweepstakes {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: solid 1.3px #e6e6e6;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.header-sweepstakes {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-header-sweepstakes {
  color: #403f4c;
  font-size: 32px;
  text-align: left;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.header-sweepstakes p {
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: #acacac;
  text-align: left;
  font-style: italic;
  font-weight: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.exit-modal-draw {
  width: 100%;
  display: flex;
  padding: 10px 10px 0 0;
  justify-content: flex-end;
}

.body-draw-confirm {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.react-awesome-modal > div > div {
  z-index: 10 !important;
}

.draw-details-list {
  display: flex;
  padding-left: 0;
}

.button-header-sweepstakes-draw-modal {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.select-component-body-initial {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 20px;
  height: 155px;
  margin-right: 14px;
}

.select-component-body-initial:hover {
  width: 135px;
  height: 160px;
}

.label-title-component {
  font-family: Rubik;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
  margin-top: 40%;
}

.image-title-component-initial {
  margin-top: 20px;
  margin-left: 30%;
  width: 70px;
  height: 70px;
}

.select-component-body-initial:hover {
  background-color: #ededee;
  cursor: pointer;
}

.body-select-component:hover {
  cursor: pointer;
  background-color: #ededee;
}

.label-title-component:hover {
  opacity: 0.3;
  cursor: pointer;
}

.label-add-file {
  width: 313px;
  height: 18px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
  margin-top: '20px';
  margin-bottom: '20px';
}

.label-component {
  margin-top: 20px;
  height: 14px;
  opacity: 0.53;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}

.corse-card-body {
  width: 300px;
  cursor: pointer;
  height: 300px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 19px;
  margin-bottom: 19px;
  background-color: #ffffff;
  margin-right: 10px;
}
.corse-card-body:hover {
  box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.14),
    0 20px 40px 0 rgba(0, 0, 0, 0.1);
}
.card-body-disable:hover {
  box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.14),
    0 20px 40px 0 rgba(0, 0, 0, 0.1);
}
.card-body-disable {
  width: 300px;
  cursor: pointer;
  height: 300px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 19px;
  margin-bottom: 19px;
  background-color: #ffffff;
  margin-right: 10px;
}
.cardbodyitem {
  background: #ffffff;
  height: 50px;
  width: 200px;
}

.title-item {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: left;
  color: #403f4c;
  margin-top: 5px;
  margin-left: 2%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.descritionitem {
  height: 32px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  color: #acacac;
  margin-top: 5px;
  text-align: center;
}
.textbtnicon {
  display: inline-block;
  margin-left: 8px;
}
.btnremove {
  height: 14px;
  border: none;
  background: #ffffff;
  margin-left: 20px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  margin-top: '2px';
  text-align: center;
  color: #acacac;
  display: inline-block;
  padding: 5px;
  opacity: 1;
}

.score-item-course {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: left;
  color: #efc75e;
  margin-left: 5px;
}

.score-item-name {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: left;
  color: #efc75e;
  margin-left: 5px;
}

.itemquant {
  width: 55.7px;
  height: 14px;
  font-family: Rubik;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;

  text-align: right;
  color: #acacac;
  margin-top: 20px;
  margin-left: 30px;
}

.btnarea {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.card-division-course {
  width: none;
  height: 8px;
  background-color: rgba(64, 63, 76, 0.3);
}
.btnitem {
  width: 50%;
  height: 100%;
  border: none;
  background: #ffffff;
  margin-right: 0px;
  margin-top: 30px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  margin-top: '2px';
  text-align: center;
  color: #acacac;
  display: inline-block;
  padding: 5px;
}

.labelbtn {
  width: 43.6px;
  height: 14px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  color: #acacac;
}

.image-course {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}
.span {
  position: absolute;
  top: 90px;
  margin-left: -175px;
}
.duration-item {
  height: 12px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: left;
  color: #acacac;
  margin-left: 5px;
}

.moduleitem {
  opacity: 0.75;
  font-family: Rubik;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #403f4c;
  margin-left: 10px;
}
.moduleitemtopic {
  opacity: 0.75;
  font-family: Rubik;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #403f4c;
  margin-left: 15%;
}

.row-course-info {
  display: flex;
  flex-flow: column;
  margin-top: 2%;
}
.row-course-attribute {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
  margin-left: 2%;
  margin-right: 2%;
}
.img-score {
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
}

.items-description {
  display: flex;
}
.row-enable-course {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 2%;
}

.img-duration {
  width: 20px;
  height: 20px;
  margin-top: 0px;
}

.toogle-course {
  background-color: white;
  width: 50px;
  z-index: 20;
  margin-bottom: -30px;
  margin-left: 5px, 50px;
  border-radius: 10px 5px 0px 5px;
  background-color: #ffffff;
  padding: 5px;
}

.delete-course {
  background-color: white;
  width: 50px;
  z-index: 20;
  margin-bottom: -30px;
  margin-left: 5px, 50px;
  border-radius: 0px 5px 10px 5px;
  background-color: #ffffff;
  padding: 5px;
}

.toogle-row {
  display: flex;
  justify-content: flex-end;
}

.delete-row {
  display: flex;
  justify-content: flex-start;
}

.list-card-course-rows {
  display: flex;
  overflow-y: scroll;
  width: 80%;
  margin-left: 1%;
  height: 350px;
  margin-left: 10%;
  overflow-y: hidden;
}

.list-cards-rows {
  display: flex;
  overflow-y: scroll;
  width: 95%;
  margin-left: 1%;
  overflow-y: hidden;
  margin-bottom: 50px;
  position: relative;
}

.blur-div {
  width: 100px;
  margin-left: 86%;
  height: 350px;
  margin-top: -360px;
  z-index: 5000;
  -webkit-filter: blur(20px);
          filter: blur(20px);
  background-color: whitesmoke;
}

.blur-div-edit-cards {
  width: 100px;
  margin-left: 90%;
  height: 450px;
  margin-top: -500px;
  z-index: 5000;
  -webkit-filter: blur(30px);
          filter: blur(30px);
  background-color: whitesmoke;
}
.row-cards {
  display: flex;
}

.blur-div-init {
  width: 100px;
  height: 350px;
  margin-top: 0px;
  margin-left: 6%;
  position: absolute;
  z-index: 5000;
  -webkit-filter: blur(20px);
          filter: blur(20px);
  background-color: white;
}

.place-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-place-holder {
  width: 250px;
  height: 280px;
}

.card-body-module {
  height: 200px;
  width: 225px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-right: 9px;
  margin-top: 19px;
  margin-bottom: 19px;
  background-color: #ffffff;
}

.row-header-module {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
.label-name-module-card {
  width: 50%;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
  margin-top: 10px;
  border-bottom: 0.5px solid #b8b8b8;
}

.label-description-module-card {
  height: 40px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
  margin-top: 20px;
}

.label-content-module-card {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
  margin-top: 10px;
}

.row-module-actions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 15%;
  border-top: 0.5px solid #b8b8b8;
}

.row-btns-card-modules {
  margin-top: 3%;
}
.row-btns-card-modules:hover {
  opacity: 0.7;
  cursor: pointer;
}
.label-btn-card-course {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
  margin-left: 5px;
  margin-top: 1px;
}
.label-btn-card-course:hover {
  cursor: pointer;
}

.form-edit-module-course {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.btn-remove-module {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-remove-module:hover {
  cursor: pointer;
}

.header-course-module {
  margin: 0;
  padding: 0;
  height: 100px;
  display: flex;
  align-items: center;
}

.label-course-module {
  margin-right: 5px;
}

.content-body-display {
  width: 300px;
  height: 450px;
  border-radius: 18px;
  box-shadow: 0 27px 53px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: solid 1.3px rgba(151, 151, 151, 0.33);
  margin-top: 50px;
  align-items: center;
  display: flex;
  flex-flow: column;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 20px;
}

.content-body-display-text {
  width: 300px;
  height: 400px;
  border-radius: 18px;
  box-shadow: 0 27px 53px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: solid 1.3px rgba(151, 151, 151, 0.33);
  margin-top: 50px;
  align-items: center;
  display: flex;
  flex-flow: column;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 20px;
}

.body-form-created-image {
}

.img-create-image {
  width: 90%;
  height: 150px;
  object-fit: cover;
}

.row-ansenwr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.actions-card-course {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  align-items: center;
  width: 100%;
}

.img-card-course {
  height: 15px;
  width: 15px;
}
.img-card-course:hover {
  cursor: pointer;
}

.label-actions {
  font-family: Rubik;
  font-size: 14.2px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

.label-actions:hover {
  cursor: pointer;
  opacity: 0.7;
}

.display-load {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 450px;
}
.save-label {
  font-family: Rubik;
  font-size: 18.2px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-top: -10px;
  color: #acacac;
}

.create-card-course-display {
  display: flex;
  overflow-y: scroll;

  overflow-y: hidden;
  margin-top: 50px;
}

.create-card-card-default-body {
  width: 300px;
  height: 450px;
  border-radius: 18px;
  box-shadow: 0 27px 53px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: solid 1.3px rgba(151, 151, 151, 0.33);
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  margin-right: 10px;
}
.create-card-card-default-body:hover {
  cursor: pointer;
  opacity: 1;
}

.ic-adicionar {
  width: 42px;
  height: 42px;
  object-fit: contain;
  opacity: 0.5;
}

.create-card-card-default-label {
  opacity: 0.46;
  font-family: Rubik;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}

.create-card-card-default-label:hover {
  cursor: pointer;
}

.create-card-kinds-display {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.create-card-kinds-display-label {
  opacity: 0.46;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  width: 300px;
  margin-top: 5px;
}

.card-kinds-option {
  height: 112px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #979797;
  opacity: 0.52;
}

.card-kinds-option:hover {
  cursor: pointer;
  opacity: 1;
}

.card-details-body {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 18px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: solid 1.3px #e6e6e6;
  margin: 20px 20rem 20px;
}

.header-title-course {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-details-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 5px;
}

.card-detail-label-title {
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-bottom: 10px;
}

.card-detail-label-tag {
  opacity: 0.65;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}

.card-detail-label-score {
  font-family: Rubik;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffb200;
  margin-left: 20px;
}

.dot {
  height: 15px;
  width: 15px;
  background-color: #ffb200;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.card-detail-img {
  float: right;
  margin-left: 50px;
  padding-right: 0px;
  border-radius: 0px 16px 16px 0px;
  height: 120px;
  max-height: 118px;
  max-width: 300px;
  width: none;
  object-fit: cover;
}

.card-details-display-column {
  display: flex;
  flex-flow: column;
  margin-left: 10px;
}

.list-cards {
  display: flex;
  overflow-y: scroll;
  overflow-y: hidden;
}

.module-row {
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-left: 15px;
}

.label-module-row {
  font-family: Rubik;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #404040;
}
.label-module-row-cards {
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666666;
  margin-left: 20px;
}

.header-details {
  align-self: flex-start;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.title-header-details {
  align-self: flex-start;
  font-family: Rubik;
  font-size: 25px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
  padding: 10px 0px 10px 20px;
}

#subheader-buttons {
  display: flex;
  margin-left: auto;
  margin-right: 20px;
}

.numbers-subtitle {
  font-size: 16px;
  text-align: left;
  color: #403f4c;
  cursor: pointer;
  margin-left: 24px;
}

.body-details {
  width: 100%;
}

.body-details-first {
  display: flex;
  padding: 0px 0px 10px 10px;
}

.menu-item-details {
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  opacity: 0.5;
  color: #403f4c;
  cursor: pointer;
}

.menu-item-details:hover {
  border-bottom: 2.1px #e5156b solid;
  cursor: pointer;
  opacity: 1;
  color: #403f4c;
  height: 100%;
}

.active {
  border-bottom: 2.1px #e5156b solid;
  opacity: 1;
}

.send-invitation-course {
  margin-right: 20px;
}

.btn-send-msg {
  width: 185px;
  height: 30px;
  border: none;
  font-size: 14px;
  color: #501f99;
  line-height: 20px;
  padding-left: 35px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background-position: left;
  background-repeat: no-repeat;
  background-color: transparent;
  background-image: url(/static/media/icon-msg.769ca67a.svg);
}

.btn-send-msg p {
  margin: 0;
  padding: 0;
}

.btn-send-msg:hover p {
  border-bottom: 1px #501f99 solid;
}

.send-notification-course {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;
  align-items: center;
  flex-direction: column;
}

.notification-title {
  margin: 0;
  padding: 0;
  font-size: 28px;
  color: #2f313d;
  font-weight: 600;
  margin-top: 10px;
  line-height: 40px;
  font-style: normal;
  font-family: Poppins;
}

.notificaiton-msg-description {
  font-size: 12px;
  color: #8c91a9;
  line-height: 16px;
  font-style: normal;
  font-family: Rubik;
  font-weight: normal;
  text-transform: uppercase;
}

.notification-body-msg {
  width: 510px;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid #ccd2e0;
}

.msg-notification-course-input {
  outline: 0;
  width: 100%;
  height: 40px;
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: 300;
  color: #2f313d;
  line-height: 24px;
  font-style: normal;
  font-family: Rubik;
  background-color: #f5f7fa !important;
  border-radius: 8px 8px 0px 0px;
}

.msg-notification-course-input::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 300;
  color: #ccd2e0;
  line-height: 24px;
  font-style: normal;
  font-family: Rubik;
}

.msg-notification-course-input::placeholder {
  font-size: 16px;
  font-weight: 300;
  color: #ccd2e0;
  line-height: 24px;
  font-style: normal;
  font-family: Rubik;
}

.notification-img-close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.notification-btn-msg {
  width: 100%;
  display: flex;
  padding: 50px 0px;
  align-items: center;
  justify-content: center;
}

.container-details {
  width: 100%;
  display: flex;
}

.table-select-details {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  background-color: #ffffff;
}

.labels-headers {
  opacity: 0.61;
  font-family: Rubik;
  font-size: 12.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-right: 8px;
}

.labels-headers:hover {
  cursor: pointer;
  color: #333333;
}

.labels-headers-marked {
  font-family: Rubik;
  font-size: 18.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-right: 8px;
}

.labels-headers-marked:hover {
  cursor: pointer;
  color: #333333;
}

.select-item-header-course {
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 8px;
  margin-right: 100px;
  width: 150px;
}

td {
  font-family: Rubik;
  font-size: 13.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
  padding: 0px 20px 0px;
  align-items: center;
}

.th-row-details {
  height: 40px;
  display: flex;
  align-items: center;
}

.avatar-user {
  width: 30px;
  align-items: center;
  margin-right: 10px;
}

.body-details-first {
  margin-left: 9px;
}

.th-progress {
  width: 450px;
  display: flex;
  position: relative;
}

.progress-bar {
  background-color: #e5156b;
}

.section-users-course {
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  margin: 10px 0px;
}

.header-list {
  margin: 0;
  padding: 0;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.user-body {
  background-color: transparent;
}

.th-name {
  width: 260px;
}

.th-name img {
  border-radius: 100%;
}

.th-email {
  width: 250px;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  font-family: Rubik;
  font-style: normal;
  padding-left: 34px;
}

.th-date-conclusion {
  width: 220px;
  padding-left: 60px;
}

.btn-certified {
  margin: 0;
  padding: 0;
  width: 200px;
  border: none;
  background-color: transparent;
}

.certificate-title-confirm {
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 24px;
  color: #2f313d;
  font-weight: 600;
  margin-top: 10px;
  line-height: 40px;
  text-align: center;
  font-style: normal;
  font-family: Poppins;
}

.certificate-btn-ok {
  margin: 30px 0px;
}

.th-certified {
  display: flex;
  justify-content: center;
}

.th-not-certified {
  display: flex;
  width: 412px;
  justify-content: center;
}

.body-container {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.container-selected-edit {
  margin: 0;
  padding: 0;
  display: flex;
  height: 7.2rem;
  padding-left: 2rem;
  align-items: center;
  justify-items: flex-start;
}

.selected-edit {
  margin: 0;
  padding: 0;
  height: 20px;
  opacity: 0.5;
  cursor: pointer;
  font-size: 16px;
  color: #403f4c;
  text-align: left;
  font-family: Rubik;
  font-style: normal;
  margin-right: 20px;
  font-weight: normal;
  line-height: normal;
  font-stretch: normal;
  padding-bottom: 20px;
  letter-spacing: normal;
}

.selected-edit:hover {
  opacity: 1;
  color: #403f4c;
  cursor: pointer;
  border-bottom: 2.1px #e5156b solid;
}

.active-edit {
  opacity: 1;
  color: #403f4c;
  cursor: pointer;
  border-bottom: 2.1px #e5156b solid;
}

.container-course-long {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  border-top: 2px solid #ececec;
}

.body-list-modules {
  width: 25%;
  border-right: 2px solid #ececec;
}

.body-create-modules {
  width: 45%;
  border-right: 2px solid #ececec;
}

.body-preview-subModules {
  width: 30%;
}

.card-module {
  width: 100%;
  height: 75px;
  display: flex;
  padding: 16px;
  cursor: pointer;
  justify-content: space-between;
  border-bottom: 1px solid #d1d4e3;
}

.card-module:hover {
  background: linear-gradient(
      0deg,
      rgba(179, 36, 124, 0.1),
      rgba(179, 36, 124, 0.1)
    ),
    #ffffff;
}

.card-select {
  background: linear-gradient(
      0deg,
      rgba(179, 36, 124, 0.1),
      rgba(179, 36, 124, 0.1)
    ),
    #ffffff;
}

.new-module-btn {
  margin: 0;
  padding: 0;
  height: 36px;
  display: flex;
  font-size: 14px;
  cursor: pointer;
  margin-top: 25px;
  color: #501f99;
  line-height: 20px;
  font-family: Rubik;
  font-style: normal;
  align-items: center;
  font-weight: normal;
  letter-spacing: 0.5px;
  justify-content: center;
  text-transform: uppercase;
}

.new-module-btn p {
  margin: 0;
  padding: 0;
}

.new-module-btn p:nth-child(1) {
  font-size: 30px;
  font-weight: bold;
  margin-right: 10px;
}

.card-title-module {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #2f313d;
  line-height: 21px;
  margin-bottom: 5px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
}

.card-description-module {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #7d86a1;
  line-height: 17px;
  font-style: normal;
  font-family: Rubik;
  font-weight: normal;
}

.container-create-modules {
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
}

.title-submodule-field {
  width: 100%;
  position: relative;
}

.title-module-preview {
  font-size: 24px;
  min-height: 30px;
  color: #333947;
  font-weight: bold;
  line-height: 24px;
  font-family: Rubik;
  font-style: normal;
}

.title-submodule-preview {
  display: flex;
  font-size: 20px;
  min-height: 30px;
  color: #333947;
  font-weight: bold;
  line-height: 24px;
  font-family: Rubik;
  font-style: normal;
  align-items: center;
  justify-content: space-between;
}

.form-details-module {
  margin: 0;
  padding: 0;
  display: flex;
  padding: 16px;
  margin-top: 16px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #ececec;
}

.removed-submodule {
  width: 20px;
  height: 20px;
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.removed-submodule:hover {
  background-color: #ececec;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.154155);
}

.component-content-submodule {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  flex-direction: column;
  justify-content: center;
  border-bottom: 0.5px solid #ccd2e0;
}

.text-title-submodule {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.text-title-submodule p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #8c91a9;
  line-height: 16px;
  font-style: normal;
  font-family: Rubik;
  font-weight: normal;
  text-transform: uppercase;
}

.body-content-submodule {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.card-content-submodule {
  width: 100px;
  height: 100px;
  display: flex;
  margin-right: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.card-content-submodule p {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #333947;
  line-height: 24px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
}

.label-details-SubModule {
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.img-submodule {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-submodule svg {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

#img-submodule-svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.desactive-img-submodule svg {
  cursor: no-drop;
}

#desactive-img-submodule-svg {
  cursor: no-drop;
}

.label-details-form {
  margin-top: 24px;
}

.container-content-submodule {
  display: flex;
  margin-top: 24px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
}

.header-content-submodule {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.content-submodule-link {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  min-height: 120px;
}

.input-titleLink {
  max-width: 250px;
}

.input-linkContent,
.input-titleLink {
  -webkit-appearance: none;
  color: rgba(0, 0, 0, 0.97);
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;

  box-sizing: border-box;
  font-size: 16px;
  min-width: 250px;
  padding: 5px;
}

.input-linkContent::-webkit-input-placeholder, .input-titleLink::-webkit-input-placeholder {
  opacity: 0.5;
}

.input-linkContent::placeholder,
.input-titleLink::placeholder {
  opacity: 0.5;
}

.input-linkContent:focus,
.input-titleLink:focus {
  outline-style: solid;
  outline-color: #1f89e5;
  outline-width: 0.2px;
  border: none !important;
  margin-bottom: 1px;
  box-shadow: inset 0 0 1.5px 0 black;
}

.description-link {
  min-height: 5px;
  opacity: 0.5;
  font-size: 14px;
  margin-left: 15px;
}

.header-title-img {
  display: flex;
  margin-bottom: 16px;
}

.header-title-img img {
  max-width: 56px;
}

.header-content-description {
  display: flex;
  margin: 8px 16px;
  flex-direction: column;
}

.header-content-description p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #8c91a9;
  line-height: 16px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
}

.header-content-description p:nth-child(2) {
  font-size: 20px;
  color: #2f313d;
  line-height: 28px;
  font-weight: bolder;
}

.container-content-submodule-text {
  width: 100%;
  height: auto;
  padding: 5px;
}

.container-content-submodule-quiz {
  width: 100%;
  height: auto;
}

.container-answers-quiz {
  width: 100%;
  display: flex;
  min-height: 40px;
  padding-left: 16px;
  align-items: center;
}

.text-answers-quiz {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.loading-container {
  position: absolute;
  height: 100%;
  width: 400px;
  margin-top: 0;
  pointer-events: none;
}

.loading-container.loading {
  color: #999999 !important;
}

.loading-container.loading textarea {
  color: #999999 !important;
}

.loading-container.loading::before {
  content: '';
  display: block;
  position: absolute;
  left: 0%;
  height: calc(100% - 5px);
  width: 80px;
  background: linear-gradient(
    to right,
    transparent 0%,
    #e8e8e8 50%,
    transparent 100%
  );
  -webkit-animation: load 0.66s cubic-bezier(0.4, 0, 0.2, 1) infinite;
          animation: load 0.66s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  -webkit-transform: translateX(-60px);
          transform: translateX(-60px);
  opacity: 0.66;
  margin-left: 30px;
}

@-webkit-keyframes load {
  from {
    left: 0%;
  }
  to {
    left: calc(100% - 32px);
  }
}

@keyframes load {
  from {
    left: 0%;
  }
  to {
    left: calc(100% - 32px);
  }
}

.view-characters {
  margin-top: -4px;
}

.video-cover-container {
  margin: 0;
  padding: 0;
  width: 510px;
  min-height: 232px;
  display: flex;
  position: relative;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
}

.video-cover-container .video-react .video-react-control-bar {
  position: relative;
}

.container-title-quiz {
  width: 100%;
  min-height: 40px;
  position: relative;
}

.container-preview-content {
  height: 100%;
}

.header-preview-content {
  padding: 16px;
}

.header-preview-content p {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 32px;
  text-align: center;
  font-style: normal;
  font-family: Rubik;
}

.body-preview-content {
  height: 100%;
  padding: 0px 16px;
}

.card-preview-content {
  padding: 24px;
  min-height: 300px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.154155);
}

.header-card-preview {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  justify-content: space-between;
  border-bottom: 2px solid #ececec;
}

.header-card-preview p {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  color: #2f313d;
  line-height: 32px;
  font-style: normal;
  text-align: center;
  font-family: Rubik;
}

.arrow-card-submodule {
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  cursor: pointer;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.arrow-card-submodule:hover {
  background-color: #ececec;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.154155);
}

.preview-title-quiz {
  font-size: 20px;
  color: #2f313d;
  line-height: 28px;
  font-style: normal;
  font-family: Rubik;
  font-weight: normal;
  font-weight: bolder;
}

.body-answers-content {
  margin: 24px 0px;
}

.content-answers-preview {
  margin: 0;
  padding: 8px;
  display: flex;
  min-height: 50px;
  margin-top: 16px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fa;
}

.active-content-answers-preview {
  background-color: #73b53b;
}

.content-answers-preview p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  color: #3a3a3a;
  line-height: 28px;
  font-family: Rubik;
  font-style: normal;
}

.active-content-answers-preview p {
  color: #f5f7fa;
}

.body-card-preview {
  margin-top: 24px;
  align-items: center;
  justify-content: center;
}

.preview-description-text p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 300;
  color: #7d86a1;
  line-height: 24px;
  font-style: normal;
  text-align: left;
  font-family: Rubik;
}

.preview-description-text p + p {
  margin-top: 1.5rem;
}

.image-preview-card {
  width: 100%;
  height: 50%;
  display: flex;
  border-radius: 8px;
  align-items: center;
  background: #ffffff;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
}

.image-preview-card-video {
  width: 100%;
}

.loading-content {
  width: 100%;
  height: 10px;
}

.ck-content p {
  font-size: 1.5rem;
}

.add__answer__new--container {
  width: 100%;
  height: 36px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.add__answer__new--container .add__answer__new {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #501f99;
  background: none;
  border: none;
  cursor: pointer;
}

.add__answer__new--container .add__answer__new:active,
.add__answer__new--container .add__answer__new:focus {
  outline: none;
}

.add__answer__new--container .add__sign {
  display: inline-block;
  margin-right: 5px;
  font-size: 20px;
  font-weight: bold;
  color: #501f99;
}

.remove-answer {
  width: 20px;
  height: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
}

.remove-answer:hover {
  background-color: #ececec;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.154155);
}

.right {
  margin-left: auto;
  padding-right: 16px;
}

.quantity {
  border: 2px solid #b4b4b4;
  border-radius: 100%;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.send-file2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-file2 .drop-zone-icon {
  width: 120px !important;
  height: 123px !important;
  position: relative !important;
  margin-right: 500px;
}

.content-submodule-file2 {
  max-height: 300px;
  box-shadow: 0px 0px 8px 0px #00000040;
}

.body-send-file2 {
  max-height: 300px;
}

.list-send-file2 {
  max-height: 270px;
  margin-top: 0px !important;
  box-shadow: none !important;
  border-left: 1px solid #00000040;
  border-radius: 0 !important;
}

.title-list-file2 {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;

  color: #000000 !important;
  opacity: 0.65 !important;
}

.body-list-file2 {
  padding: 0 !important;
  border-top: 1px solid #00000040;
  height: 100%;
  overflow-x: hidden !important;
  justify-content: initial !important;
}

.file-selected2 {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;

  color: #000000 !important;
  opacity: 0.6 !important;

  border-bottom: 1px solid #00000040;
  min-height: 50px !important;
}

.file-name2 {
  margin-left: 5px !important;
}

.form-group-input {
  width: 100%;
  display: flex;
  margin-top: 24px;
  flex-flow: column;
  position: relative;
  align-items: center;
}

.form-group-input-row {
  width: 100%;
  min-height: 60px;
  display: flex;
  margin-top: 24px;
  align-items: center;
  justify-content: space-between;
}

.form-group-input-row:last-of-type {
  justify-content: flex-end;
}

.body-form-initialo-course {
  display: flex;
  margin-top: 40px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}

.display-form {
  width: 100%;
  display: flex;
  max-width: 510px;
  align-items: center;
  justify-content: flex-start;
}

.form-input-body {
  width: 100%;
}

.img-create-course {
  float: right;
  margin-left: 110px;
  padding-right: 0px;

  margin-left: 160px;
  height: none;
  max-height: 450px;
  max-width: 430px;
  min-width: 430px;
  min-height: 450px;
  width: none;
  object-fit: cover;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  border-radius: 15px;
}

.title-name-course {
  margin: 0;
  padding: 0;
  width: 510px;
  font-size: 24px;
  text-align: left;
  color: #333947;
  font-weight: bold;
  line-height: 24px;
  font-style: normal;
  font-family: Rubik;
  margin-bottom: 24px;
}

.form-group-input-row div:nth-child(1) {
  padding-right: 10px;
}

.form-level {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.form-score-certificate {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.score-input input {
  background-color: transparent;
}

.score-input input:disabled {
  color: #b3b3b3 !important;
}

.form-has-certificate {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.form-group-input-btn {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.container-modal-patch-course {
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.header-modal-patch-course {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.body-modal-patch-course {
  width: 100%;
  height: 85%;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.body-modal-patch-course p {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 500;
  padding-left: 15px;
  line-height: 24px;
  font-family: Rubik;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  margin-bottom: 24px;
}

.btn-close-modal-patch {
  margin: 0;
  width: 100%;
  padding: 0;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: aqua;
}

.view-characters-description {
  width: 100%;
  font-size: 12px;
  text-align: left;
}

.segmentationTag-course {
  width: 100%;
}

.form-modality {
  display: flex;
  align-items: center;
  color: #b3b3b3;
  flex: 1 1;
}

.form-modality .form-level:first-child {
  width: auto;
  margin-right: 32px;
}

.form-modality .form-level {
  width: 66%;
}

.form-modality .form-level > div {
  width: 100% !important;
}

.form-modality-label {
  font-weight: 700;
  font-size: 13px;
  line-height: 1.33;
  color: #b3b3b3;
  width: 60%;
}

.form-modality-label--points {
  margin: 40px 0 -10px;
}

.form-label {
  padding: 0 7px;
  cursor: pointer;
}

.preview-publication {
  width: 327px;
  height: 350px;
  border-radius: 21px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  position: absolute;
}

.preview-publication-img {
  width: 327px;
  height: 170px;
  object-fit: cover;
  border-radius: 21px 21px 0px 0px;
}

.preview-publication-video {
  object-fit: cover;
  border-radius: 11px 11px 0px 0px;
}
.preview-publication-externo {
  border: none;
  border-radius: 11px 11px 0px 0px;
  width: 327px;
  height: 170px;
}
.preview-corse-img {
  width: 327px;
  height: 140px;
  object-fit: cover;
  border-radius: 21px 21px 0px 0px;
}
.preview-corse-img-preview {
  width: 170px;
  height: 150px;
  object-fit: cover;
  border-radius: 21px 21px 0px 0px;
  margin-left: 25%;
}
.img-default {
}
.text-area-preview {
  border: none;
  width: 98%;
  opacity: 0.39;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
  height: 450px;
  background-color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
  overflow: auto;
}

.text-area-preview-course {
  border: none;
  width: 100%;
  opacity: 0.39;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
  background-color: #ffffff;
  margin-top: 10px;

  overflow: auto;
}
.preview-publication-division {
  width: 327px;
  height: 8px;
  background-color: rgba(64, 63, 76, 0.3);
}

.preview-publication-avatar {
  width: 17px;
  height: 17px;
  margin-top: 10px;
  background-color: #d8d8d8;
}

.preview-publication-user {
  margin-top: 25px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
  align-content: 'left';
}

.preview-publication-title {
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  margin-top: 15px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.preview-publication-text {
  opacity: 0.57;
  font-family: Rubik;
  margin-top: 10px;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.tag-text {
  opacity: 0.45;
  border-radius: 110px;
  background-color: #d8d8d8;
  font-family: Rubik;
  font-size: 12.1px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0c0b0b;
}

.est-curso-desc {
  display: flex;
  margin-top: 20px;
  list-style-type: none;
  align-items: center;
  padding: 0;
}

li {
  margin-left: 5px;
  margin-right: 5px;
}

.text-duration {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
}

.preview-module {
  width: 327px;
  height: 450px;
  border-radius: 21px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  margin-top: 0px;
  margin-left: 10px;
  position: absolute;
}
.preview-module-quiz {
  width: 220px;
  height: 500px;
  border-radius: 21px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  margin-top: -175px;
  margin-left: 50px;
}

.preview-module-img {
  width: 100%;
  height: 192px;
  object-fit: cover;
  border-radius: 21px 21px 0px 0px;
}
.preview-module-title-course {
  margin-top: 20px;
  margin-left: 10px;
  width: 90%;
  border: none;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 5px;
  color: #403f4c;
}
.tag-text-course {
  padding: 5px;
  opacity: 0.45;
  width: 60px;
  margin-top: 25px;
  border-radius: 110px;
  background-color: #d8d8d8;
  font-family: Rubik;
  font-size: 12.1px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0c0b0b;
}
.preview-module-title-module {
  margin-top: 20px;
  margin-left: 10px;
  width: 90%;
  border: none;
  font-family: Rubik;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 5px;
  color: #403f4c;
}
.divisoria {
  width: 290px;
  height: 1px;

  margin-left: 20px;
  margin-right: 20px;
  background-color: rgba(64, 63, 76, 0.3);
}

.preview-module-descriptio-module {
  margin-top: 20px;
  margin-left: 10px;
  width: 90%;
  opacity: 0.39;
  border: none;
  font-family: Rubik;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 5px;
  color: #403f4c;
}
.preview-module-duration-course {
  margin-top: 20px;
  margin-left: 10px;
  width: 90%;
  border: none;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 5px;
  color: #403f4c;
}

.preview-module-division {
  width: 327px;
  height: 8px;
  background-color: rgba(64, 63, 76, 0.3);
}

.preview-module-avatar {
  width: 17px;
  height: 17px;
  margin-top: 10px;
  background-color: #d8d8d8;
}

.preview-module-title {
  margin-top: 0px;
  margin-left: 10px;
  width: 90%;
  border: none;
  opacity: 0.39;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 10px;
  color: #403f4c;
}

.preview-module-text {
  opacity: 0.57;
  width: 50px;
  max-width: 10px;
  font-family: Rubik;
  margin-top: 1px;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  white-space: pre;
  color: #000000;
}

.option {
  height: 50px;
  width: 190px;
  margin-top: 15px;
  box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 11px 11px 11px 11px;
  padding: 15px;
  background-color: #ffffff;
  text-align: center;
}

.img-default-module {
  height: 70px;
  margin-left: 80px;
  margin-top: 20px;
}
.img-default-simple-module {
  height: 50px;
  width: 100px;
  margin-top: 20px;
  margin-left: 60px;
  border-radius: 21px 21px 0px 0px;
}
.img-content-module {
  height: 100px;
  width: 190px;
  margin-top: 20px;
  object-fit: cover;
  border-radius: 11px 11px 11px 11px;
}
.text-area-question-module {
  margin-top: 50px;
  margin-left: 10px;
  width: 90%;
  border: none;
  opacity: 0.39;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.card-details-module {
  margin-top: 10px;
  height: 100px;
  background-color: #ffffff;
  padding: 10px;
  padding-left: 20px;
  border-radius: 16px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
.module-title {
  font-family: Rubik;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}
.module-description {
  opacity: 0.26;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.score-text {
  height: 35px;
  font-family: Rubik;
  font-size: 25.3px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #efc75e;
}
.card-content-hide {
  background-color: #ffffff;
  margin-top: 10px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 16px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14);
  cursor: pointer;
  height: 100%;
}

.text-card-content {
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.ico-drop {
  margin-top: -10px;
  width: 30px;
  padding: 10px;
  border-radius: 16px;
}
.ico-remove {
  width: 33px;
  height: 33px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
}
.ico-content {
  width: 88px;
  height: 23px;
  margin-top: 7px;
  border-radius: 14px;
  opacity: 0.41;
  border: solid 1px #403f4c;
  opacity: 0.41;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 3px;
}

.preview-content {
  width: 220px;
  height: 350px;
  border-radius: 21px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  position: fixed;
  margin-top: -10%;
  margin-left: 50px;
}
.preview-content-quiz {
  width: 220px;
  height: 450px;
  border-radius: 21px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  margin-top: -70px;
  margin-left: 70px;
  position: absolute;
}

.preview-content-img {
  width: 100%;
  height: 90px;
  object-fit: cover;
  border-radius: 21px 21px 21px 21px;
}

.preview-content-division {
  width: 327px;
  height: 8px;
  background-color: rgba(64, 63, 76, 0.3);
}

.preview-content-avatar {
  width: 17px;
  height: 17px;
  margin-top: 10px;
  background-color: #d8d8d8;
}

.preview-content-title {
  margin-top: 50px;
  margin-left: 10px;
  width: 90%;
  border: none;
  opacity: 0.39;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 20px;
  color: #403f4c;
}

.preview-content-text {
  opacity: 0.57;
  width: 50px;
  max-width: 10px;
  font-family: Rubik;
  margin-top: 1px;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  white-space: pre;
  color: #000000;
}

.option {
  height: 50px;
  width: 190px;
  margin-top: 15px;
  box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 11px 11px 11px 11px;
  padding: 15px;
  background-color: #ffffff;
  text-align: center;
}

.img-default-content {
  height: 100px;
  margin-left: 40px;
  margin-top: 20px;
}
.img-default-simple {
  height: 50px;
  width: 100px;
  margin-top: 20px;
  margin-left: 60px;
  border-radius: 21px 21px 0px 0px;
}
.img-default-quiz {
  margin-left: 70px;
  margin-top: 30px;
}
.img-content {
  height: 100px;
  width: 190px;
  margin-top: 20px;
  object-fit: cover;
  border-radius: 11px 11px 11px 11px;
}
.text-area-question {
  margin-top: 50px;
  margin-left: 10px;
  width: 90%;
  border: none;
  opacity: 0.39;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.product-card-body {
  width: 280px;
  cursor: pointer;
  height: 300px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 19px;
  margin-bottom: 50px;
  background-color: #ffffff;
  margin-right: 10px;
}

.card-product-header {
  display: flex;
  flex-flow: column;
}

.image-product-card {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.title-header-card-product {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
  margin-top: 19px;
  max-width: 225px;
  padding: 0 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.description-card-product {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
  max-width: 225px;
  padding: 0 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.row-product-attributes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;
}

.label-score-product {
  height: 27px;
  font-family: Rubik;
  font-size: 16px;
  margin-top: 2px;
  margin-left: 5px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #efc75e;
}

.row-inside-attributes {
  display: flex;
  align-items: center;
}

.amout-products {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #acacac;
}

.row-actions-card-product {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 5%;
  border-top: 0.5px solid #b8b8b8;
}

.place-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img-place-holder {
  width: 250px;
  height: 280px;
}

.row-card-corses-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 15px;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}

.view-characters {
  font-size: 12px;
}

.request-error-message {
  margin: 0;
  font-size: 36px;
  font-weight: 600;
}

.loader-container {
  width: 100%;
  height: 100%;
  margin-top: 100px;
  -webkit-transform: translateX(calc(100px)) translateY(50% - 100px);
          transform: translateX(calc(100px)) translateY(50% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.loader-container__heading {
  margin-top: 32px;
  font-size: 36px;
}

.footer-pagination {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.preview-product {
  width: 350px;
  border-radius: 21px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  position: absolute;
  padding-bottom: 32px;
}
.preview-product:hover {
  cursor: pointer;
}
.preview-product-img {
  object-fit: cover;
  border-radius: 21px 21px 0px 0px;
}
.preview-product-video {
  width: 220px;
  height: 150px;
  border-radius: 21px 21px 0px 0px;
}
.img-default-product {
  width: 170px;
  height: 150px;
  margin-left: 24%;
  object-fit: cover;
  border-radius: 21px 21px 0px 0px;
}
.img-default-product-drop {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 21px 21px 0px 0px;
}

.preview-product-division {
  width: 100%;
  height: 8px;
  background-color: rgba(64, 63, 76, 0.3);
}

.preview-product-avatar {
  width: 17px;
  height: 17px;
  margin-top: 10px;
  background-color: #d8d8d8;
}

.preview-product-user {
  margin-top: 25px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
  align-content: 'left';
}

.preview-product-title {
  max-width: 350px;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  word-break: break-all;
  margin-top: 15px;
  margin-left: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.preview-product-text {
  opacity: 0.57;
  font-family: Rubik;
  margin-top: 10px;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  margin-left: 20px;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.tag-text {
  padding: 10px;
  opacity: 0.45;
  border-radius: 110px;
  background-color: #d8d8d8;
  margin-top: 3px;
  font-family: Rubik;
  font-size: 12.1px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0c0b0b;
}

.row-score-text {
  height: 27px;
  display: flex;
  margin-top: 15px;
  margin-left: 30px;
  text-align: center;
  align-items: center;
}

.text-score {
  margin: 0;
  height: 100%;
  font-size: 16px;
  color: #efc75e;
  padding-top: 1px;
  font-weight: 600;
  padding-left: 5px;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.text-product {
  margin-top: 10px;
  height: 14px;
  margin-left: 12px;
  margin-right: 10px;
  font-family: Rubik;

  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #acacac;
}

.text-product .type-description {
  color: #acacac;
  font-size: 14px;
  opacity: 0.66;
}

.img-product {
  height: 14px;
  margin-left: 12px;
  margin-right: 8px;
}

.text-area-preview-product {
  border: none;
  width: 300px;
  height: 70px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.preview-product-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.product-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.history-label {
  height: 26px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
  cursor: pointer;
}

.history-label:hover {
  cursor: pointer;
  border-bottom: 2.1px #b3247c solid !important;
}

.name-product {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.name-product-container {
  width: 100%;
  padding-left: 8px;
}

.name-product-heading {
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.body-card {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.9;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #cccccc;
}

.img-place-holder {
  margin-top: 50px;
  width: 400px;
  height: 300px;
  margin-bottom: 50px;
}

.labe-dialog-cofirm {
  height: 18px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.label-dialog-push {
  height: 14px;
  opacity: 0.41;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.item-label-product {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #403f4c;
}

.item-label-arrow {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.item-label-user {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.cancel-btn-dialog {
  width: 76px;
  height: 18px;
  font-family: Rubik;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.cancel-btn-dialog:hover {
  cursor: pointer;
  border-bottom: #e5156b !important;
  border-bottom: 2.1px #e5156b solid !important;
}

.card-product-column {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 160px;
  padding: 0 8px;
  text-align: center;
}

.card-product-column .btn-click {
  width: 100%;
}

.card-product-column .btn-click div {
  font-size: 14px !important;
}

/* .card-product-column:first-of-type {
  max-width: 308px;
} */

.card-product-column.ico-imag {
  max-width: 16px;
}

.ico-imag:hover {
  cursor: pointer;
}

.card-product-column div {
  text-align: center;
}

.dialog-history--data {
  padding: 0 1.25rem;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialog-history--heading {
  margin-top: 10px;
  font-family: Rubik;
  font-size: 20px;
  font-weight: bold;
  color: #403f4c;
}

.dialog-history--value {
  height: 18px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  color: #403f4c;
}

.label-tag-discleimer {
  margin-top: 5px;
  margin-bottom: 10px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.edit-user-div {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

.div-body-tag {
  margin-top: 5px;
  margin-bottom: 28px;
  height: 1px;
  opacity: 0.15;
  background-color: #979797;
}

.description-tag {
  opacity: 0.39;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.user-association {
  opacity: 0.55;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.user-association-2 {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: right;
  color: #403f4c;
}
.div-vertical-tags {
  width: 1px;
  height: 84.3px;
  opacity: 0.43;
  border: solid 1px #979797;
}

.tag-exp {
  margin-top: 6px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

.img-edit{
  width: 2.2rem;
}

.card-product-column > div{
  color: #808080;
  font-family: Rubik;
  font-size: 16px;
}

.img-edit:hover {
  cursor: pointer;
}

.label-tag-name {
  width: 170px;
  height: 36px;
  font-family: Rubik;
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.img-removed-edit {
  display: flex;
}

.img-removed-edit img {
  margin-right: 10px;
}

.removed-sector-body {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  text-align: center;
  align-items: center;
  padding: 0px 40px 0px;
  justify-content: center;
}

.body-button-sector-removed {
  margin-top: 20px;
}

.body-list-sector-users {
  margin: 0;
  padding: 0;
  width: 80%;
  height: 150px;
  display: flex;
  margin-top: 20px;
  overflow-y: auto;
  align-items: center;
  background: #ffffff;
  flex-direction: column;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.body-list-container-sector {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.merge-tags__check-box-label {
  display: flex !important;
}

.hiddenOverflow div:not(#infiniteScrollDiv) {
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.hiddenOverflow tr {
  display: flex;
}

.hiddenOverflow tr > td,
.hiddenOverflow tr > th {
  min-width: 300px;
  max-width: 300px;
  display: flex;
  align-items: center;
}

.tableRow:nth-child(even) > div{
  background: #F4F6FB;
}

.tableRow > div{
  border-radius: 8px;
  padding: 2.5rem 0;
}

.div-toogle-tagList > div > div > div {
  width: 40px !important;
}

.div-toogle-tagList > div > div > div > div:first-child {
  height: 2.8rem !important;
  width: 120% !important;
}

.div-toogle-tagList > div > div > div > div:last-child {
  top: 0.75rem !important;
}

/* Quando ativo */
.div-toogle-tagList-active > div > div > div > div:last-child {
  margin-left: -17px !important;
}

/* Quando inativo */
.div-toogle-tagList-inactive > div > div > div > div:last-child {
  left: 8px !important;
}

.container-body {
  margin: 0px 20px 0px;
}

.title-send-file {
  font-size: 25px;
  margin-top: 20px;
  font-weight: 500;
  color: #403f4c;
  font-style: normal;
  margin-bottom: 30px;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.notify-users {
  display: flex;
  flex-direction: row;
  padding: 0px 0px 10px 20px;
}

.title-notify-users {
  margin: 0;
  padding: 0;
  width: 160px;
  font-size: 14px;
  font-weight: 500;
  color: #403f4c;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.body-send-file {
  display: flex;
  flex-direction: row;
}

.send-file {
  width: 65%;
  display: flex;
  flex-direction: column;
}

.list-send-file {
  width: 35%;
  display: flex;
  height: 500px;
  display: flex;
  margin-top: 20px;
  border-radius: 10px;
  align-items: center;
  background: #ffffff;
  flex-direction: column;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.body-list-file {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  max-height: 100%;
  overflow-y: auto;
  align-items: center;
  padding: 15px 0px 15px;
  flex-direction: column;
  justify-content: center;
}

.title-list-file {
  padding: 0;
  margin: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #403f4c;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.header-files {
  width: 100%;
  display: flex;
  font-size: 10px;
  color: #999999;
  font-style: normal;
  line-height: normal;
  font-weight: normal;
  padding-bottom: 10px;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
  justify-content: flex-start;
  border-bottom: 2px solid #dee2e6;
}

.file-selected {
  width: 100%;
  height: 20px;
  display: flex;
  font-size: 10px;
  margin-top: 10px;
  color: #808080;
  font-style: normal;
  line-height: normal;
  flex-direction: row;
  font-weight: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.file-name {
  width: 80%;
  margin-left: 20px;
}

.file-action {
  width: 20%;
  margin-right: 25px;
  text-align: center;
  justify-content: center;
}

.header-row-notification-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 5px;
  border-radius: 5px;
}

.header-row-notification-header:hover {
  cursor: pointer;
}

.row-actions-dialog-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.row-actions-dialog-btn {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 5%;
}
.label-header-dialog {
  font-family: Rubik;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.column-notification-dialog {
  display: flex;
  align-items: center;
  flex-flow: column;
  margin-top: 20px;
}

.column-notification-text {
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 3%;
}

.users-row-select {
  height: 200px;
  overflow-y: scroll;
  margin-top: 30px;
}

.row-user-select {
  height: 90px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #999999;
  margin-right: 2%;
  margin-left: 5%;
  margin-top: 20px;
  padding: 20px;
  margin-bottom: 5px;
  display: flex;
  flex-flow: column;
}

.row-user-attributes {
  display: flex;
  padding-left: 0px;
  padding-right: 0px;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  height: 100px;
  width: 70%;
}

.name-user {
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-left: 10px;
  width: 200px;
}

.row-btn-submit {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 2%;
  margin-top: 5%;
}

.row-placeholder-dialog {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 2%;
  margin-left: 5%;
}

.margin-top-style {
  height: 8px;
  background-color: #e5156b;
  margin-top: -12px;
  margin-left: -13px;
  margin-right: -13px;
  border-radius: 5px;
}

.form-send-push {
  width: 500px;
}

.column-row-table {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 150px;
}

.column-row-text {
  max-width: 200px;
}

.row-header-calendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2%;
}
.label-header-calendar {
  height: 32px;
  font-family: Rubik;
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}
.menu-item-cancel {
  font-family: Rubik;

  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  opacity: 0.5;
  color: #acacac;
  cursor: pointer;
}

.menu-item-cancel:hover {
  border-bottom: #e5156b !important;
  border-bottom: 2.1px #e5156b solid !important;
  cursor: pointer;
  opacity: 1;
  height: 100%;
  color: #403f4c;
}
.actions-header {
  display: flex;
  align-items: center;
}

.body-event-row {
  display: flex;
  flex-flow: column;
  margin: 5px;
  overflow-y: scroll;
}

.title-event-row {
  font-family: Rubik;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}
.description-event-row {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
  max-height: 170px;
}

.tag-event-row {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}

.tag-event-row-content {
  display: flex;
  flex-flow: row;
}
.divisori-event-row {
  width: 100%;
  border: solid 1px #e2e2e2;
  margin-top: 10%;
  margin-bottom: 5%;
  height: 1px;
  opacity: 0.7;
}
.body-event {
  overflow: scroll;
}
.header-row-event {
  display: flex;
}

.img-remove-event {
  margin-right: 4%;
}
.img-remove-event:hover {
  cursor: pointer;
}

.img-edit:hover {
  cursor: pointer;
}
.row-header-event {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.body-event-img {
  display: flex;
  justify-content: space-around;
  width: 20%;
}

.div-line {
  margin-top: 2%;
  width: 70%;
  height: 1px;
  border: solid 1px #dedcdc;
  opacity: 0.7;
  margin-bottom: 2%;
}
.body-form-title {
  display: flex;
  width: 80%;
  margin-top: 1%;
  margin-bottom: 1%;
}
.create-event .body-form-title:first-child {
  justify-content: space-between;
}

.body-form-envet-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin-top: 1%;
  margin-bottom: 1%;
}
.body-form-tag {
  width: 50%;
}
.body-form-date {
  width: 30%;
}

.body-calendar-form {
  margin-top: 4%;
  width: 70%;
  opacity: 0.6;
}

.body-calendar-form-valid {
  margin-top: 4%;
  width: 70%;
}
.create-event {
  display: flex;
}

.body-preview-event {
  margin-top: 4%;
  height: 500px;
  width: 30%;
  border-top: 5px solid #e5156b;
  border-radius: 8px;
  box-shadow: 0 27px 53px 0 rgba(0, 0, 0, 0.17);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}
.date-today-label {
  font-family: Rubik;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.header-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 5%;
}
.label-no-events {
  margin-top: 56%;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}
.user-association-tags {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-left: 1%;
  color: #999999;
  margin-top: 6%;
}
.body-form-tag-row {
  display: flex;
  align-items: center;
}

.common-label {
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  text-align: left;
  color: #333333;
  margin-right: 2px;
  margin-left: -4px;
}
.img-click:hover {
  cursor: pointer;
}

.input-holiday {
  display: flex;
  align-items: center;
  min-width: 115px;
}

input[type='time']::-webkit-clear-button,
input[type='time']::-webkit-outer-spin-button,
input[type='time']::-webkit-input-placeholder,
input[type='time']::-webkit-inner-spin-button {
  display: none;
}

.body-comfirm-event {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.label-comfirm-text {
  font-size: 36px;
  margin-top: 30px;
  color: #3b3b3b;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.label-comfirm-button {
  margin-top: 15px;
  margin-bottom: 25px;
}

.body-modal {
  border-radius: 14px;
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.22);
  background-color: #ffffff;
  border: solid 1.2px #dcdcdc;
}

.modal-header {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-flow: column;
}

.main-label-import {
  font-family: Rubik;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}
.secondary-label-import {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  margin-bottom: 2%;
}

.modal-header-import {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: 15%;
  border-bottom: solid 1px rgba(153, 153, 153, 0.62);
}

.container-header {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: 15%;
}

.text-label-description {
  font-size: 10px;
  color: #999999;
  text-align: center;
}

.img-header-import {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  padding-left: 2%;
  padding-right: 2%;
}

.img-header-import img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.description-body-text {
  display: flex;
  height: 58vh;
  overflow: scroll;
  justify-content: left;
  flex-flow: column;
  margin-top: 0;
  padding-right: 2%;
  padding-left: 2%;
}

.title-label {
  font-family: Rubik;
  font-size: 16px;
  margin-top: 10px;
  color: #333333;
  font-weight: 600;
}

.text-label-title {
  font-size: 18px;
  text-align: left;
  margin-bottom: 0;
  color: #3b3b3b;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.list-label {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
  margin-bottom: 1%;
  padding-left: 0;
}

.label-text-strong {
  font-family: Rubik;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 3px;
  color: #3b3b3b;
  cursor: pointer;
}

.drop-zone-link {
  display: flex;
  padding-top: 2px;
  align-items: center;
  justify-items: center;
  text-align: center;
  width: 170px;
  height: 25px;
  cursor: pointer;
}

a:link {
  text-decoration: none;
}

.text-label {
  font-size: 16px;
  color: #999999;
  margin: 5px 0px 5px;
}

.text-label-regular {
  font-size: 16px;
  color: #3b3b3b;
  margin: 5px 0px 5px;
}

.secondary-label-list-down {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
  margin-bottom: 2%;
  margin-top: 2%;
  padding-left: 0;
}
.secondary-label-list-down:hover {
  cursor: pointer;
  border-bottom: #999999;
}

.modal-actions-import {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 30px 2% 30px;
}

.text-title-color {
  font-family: Rubik;
  color: #b3247c;
  font-size: 16px;
  margin-top: 10px;
  font-weight: 600;
  background-color: transparent;
}

.drop-zone-input {
  border-radius: 7px;
  align-items: center;
  width: 200px;
  height: 40px;
  background-color: #ffffff;
  border: solid 1px #979797;
  background-color: #ffffff;
}
.label-drop-zone {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 5%;
}

.drag-area {
  height: 100%;
}

.container-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
  height: 100%;
  width: 100%;
}

.body-modal {
  border-radius: 14px;
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.22);
  background-color: #ffffff;
}

.modal-header-validation {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-flow: column;
  height: 100%;
  margin-top: 10%;
}

.main-label-import {
  font-family: Rubik;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}
.secondary-label-import {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  margin-bottom: 2%;
}

.modal-header-import {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  height: 15%;
}

.imag-header-import {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  padding-left: 2%;
  padding-right: 2%;
}

.description-text-body {
  display: flex;
  justify-content: left;
  flex-flow: column;
  margin-top: 2%;
  padding-right: 2%;
  padding-left: 2%;
}

.text-label-main {
  font-family: Rubik;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.secondary-label-list {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
  margin-bottom: 2%;
  margin-top: 2%;
  padding-left: 0;
}
.secondary-label-list-down {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
  margin-bottom: 2%;
  margin-top: 2%;
  padding-left: 0;
}
.secondary-label-list-down:hover {
  cursor: pointer;
  border-bottom: #999999;
}

.modal-actions-import {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 2%;
  padding-left: 2%;
}
.drop-zone-input {
  border-radius: 7px;
  width: 200px;
  height: 40px;
  background-color: #ffffff;
  border: solid 1px #979797;
  background-color: #ffffff;
}
.label-drop-zone {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 5%;
}

.container-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
  height: 100%;
  width: 100%;
}

.body-modal {
  border-radius: 14px;
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.22);
  background-color: #ffffff;
}

.modal-header-validation {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-flow: column;
  height: 100%;
  margin-top: 5%;
}

.main-label-import {
  font-family: Rubik;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}
.secondary-label-import {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  margin-bottom: 2%;
}

.modal-header-import {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  height: 15%;
}

.imag-header-import {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  padding-left: 2%;
  padding-right: 2%;
}

.description-text-body {
  display: flex;
  justify-content: left;
  flex-flow: column;
  margin-top: 2%;
  padding-right: 2%;
  padding-left: 2%;
}

.text-label-main {
  font-family: Rubik;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.secondary-label-list {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
  margin-bottom: 2%;
  margin-top: 2%;
  padding-left: 0;
}
.secondary-label-list-down {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
  margin-bottom: 2%;
  margin-top: 2%;
  padding-left: 0;
}
.secondary-label-list-down:hover {
  cursor: pointer;
  border-bottom: #999999;
}

.modal-actions-import {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 2%;
  padding-left: 2%;
}
.drop-zone-input {
  border-radius: 7px;
  width: 200px;
  height: 40px;
  background-color: #ffffff;
  border: solid 1px #979797;
  background-color: #ffffff;
}
.label-drop-zone {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 5%;
}

.container-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
  height: 100%;
  width: 100%;
}

.error-description {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}

.img-exit-import {
  width: 15px;
  height: 15px;
}
.img-exit-import:hover {
  cursor: pointer;
}

.header-exit {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}

.body-modal {
  border-radius: 14px;
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.22);
  background-color: #ffffff;
}

.modal-header-validation {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-flow: column;
  height: 100%;
  margin-top: 5%;
}

.main-label-import {
  font-family: Rubik;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}
.secondary-label-import {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  margin-bottom: 2%;
}

.modal-header-import {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  height: 15%;
}

.imag-header-import {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  padding-left: 2%;
  padding-right: 2%;
}

.description-text-body {
  display: flex;
  justify-content: left;
  flex-flow: column;
  margin-top: 2%;
  padding-right: 2%;
  padding-left: 2%;
}

.text-label-main {
  font-family: Rubik;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.secondary-label-list {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
  margin-bottom: 2%;
  margin-top: 2%;
  padding-left: 0;
}
.secondary-label-list-down {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
  margin-bottom: 2%;
  margin-top: 2%;
  padding-left: 0;
}
.secondary-label-list-down:hover {
  cursor: pointer;
  border-bottom: #999999;
}

.modal-actions-import {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 2%;
  padding-left: 2%;
}
.drop-zone-input {
  border-radius: 7px;
  width: 200px;
  height: 40px;
  background-color: #ffffff;
  border: solid 1px #979797;
  background-color: #ffffff;
}
.label-drop-zone {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 5%;
}

.container-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
  height: 100%;
  width: 100%;
}

.error-label {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #333333;
}

.img-exit-import {
  width: 15px;
  height: 15px;
}
.img-exit-import:hover {
  cursor: pointer;
}

.header-exit {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}

.header-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-calendar-action {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: #333333;
}

.header-calendar-action:hover {
  border-bottom: #e5156b !important;
  border-bottom: 2.1px #e5156b solid !important;
  cursor: pointer;
  opacity: 0.7;
  color: #403f4c;
}

.calendar-header-title {
  font-family: Rubik;
  font-size: 16px;
  color: #808080;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 0px !important;
}

.actions-calendar-title {
  margin-top: 2%;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 30%;
}

.header-actions-row-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5rem;
}

.first-colum {
  display: flex;
  flex-flow: column;
}

.title-table {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.89;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}
.description-table {
  opacity: 0.5;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.header-table-names {
  opacity: 0.61;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}
.timer-row {
  display: flex;
  flex-flow: column;
}

.actions-calendar-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paginationHome {
  width: 100%;
  padding-right: 39px;
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}

.paginationClass {
  font-weight: bold;
  text-decoration: underline;
}

.paginationItem {
  margin: 0 5px;
}

.paginationLink {
  color: #acacac;
  font-size: 15px;
}

.body_container {
  margin-left: 64px;
  display: flex;
  justify-content: initial;
  align-items: initial;
}

.score,
.end-date,
.not_score_after,
.segmentation {
  display: flex;
  flex-direction: column;
  margin-right: 30px;

  font-family: 'Rubik';
  font-size: 18px;
  font-weight: 500;
  color: #403f4c;
}

.segmentation ul {
  display: flex;
  justify-content: initial;
  align-items: initial;
  flex-wrap: wrap;
  margin-left: -45px;
  max-width: 840px;
}

.segmentation ul li {
  margin-right: 10px;
}

.score > span,
.end-date > span,
.not_score_after > span,
.segmentation > span {
  font-size: 12px;
  color: rgba(80, 80, 80, 0.5);
  font-family: 'Rubik', sans-serif;
  font-weight: 300;
}

.form-options {
  display: flex;
}

.form-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4%;
  margin-bottom: 2%;
}

.Container-header {
  display: flex;
}

.header-child {
  display: flex;
  margin-right: 105px;
  padding-bottom: 10px;
  justify-content: flex-end;
}

.enable-quiz {
  width: 100%;
  display: flex;
  align-items: center;
}

.quiz-title p {
  margin: 0;
}

:root {
  --default-font: Rubik, sans-serif;
}

.container-draw-details {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  padding: 16px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.body-draw-details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-draw-details {
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
}

.header-draw-details {
  font-size: 32px;
  color: #2f313d;
  font-style: normal;
  font-family: 'Rubik';
}

.info-details {
  font-size: 18px;
  font-family: 'Rubik';
  color: #4b4e5c;
  font-style: normal;
  font-weight: normal;
  padding-bottom: 8px;
}

.info-details span {
  color: #808080;
}

.img-user-winner {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 100%;
}

.body-user-winners-list {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  margin-top: 10px;
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}

.user-winners-list {
  width: 100%;
  height: 50px;
  display: flex;
  padding: 16px;
  font-size: 16px;
  color: #4b4e5c;
  line-height: 19px;
  font-family: Rubik;
  font-style: normal;
  align-items: center;
  font-weight: normal;
}

.user-winners-list:nth-of-type(odd) {
  background: #e4e4e5;
}

.select-form-details {
  margin: 20px 55px 0px;
}

.container-body {
  margin: 0px 55px 0px;
}

.body-view-world {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.body-preview-form {
  width: 32%;
}

.body-details-world {
  width: 67%;
  display: flex;
  flex-direction: column;
}

.details-wolrd {
  padding: 16px;
  border-radius: 2px;
  margin: 0px 10px 10px;
  background-color: #ffffff;
  border-bottom: solid 1.3px #e6e6e6;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.title-world {
  font-size: 20px;
  text-align: left;
  color: #403f4c;
  font-weight: 600;
  line-height: 32px;
  font-style: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.first-detais {
  display: flex;
  justify-content: initial;
  padding-left: 10px;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #2f313d;
}

.first-detais > .segmentation-container {
  flex-wrap: wrap;
}

.first-detais > .deadline-responses,
.first-detais > .deadline-gamification {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.first-detais > .deadline-responses span,
.first-detais > .deadline-gamification span {
  font-size: 12px;
  color: rgba(80, 80, 80, 0.5);
}

.form-progress {
  padding: 0;
  height: 60px;
  display: flex;
  align-items: center;
}

.progress {
  height: 4px;
  width: 460px;
  margin: 0px 10px 0px;
}

.imagicon {
  width: 20px;
}

.progress-description {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  color: #2f313d;
  line-height: 16px;
  margin-right: 10px;
  font-family: Rubik, sans-serif;
  font-style: normal;
}

.tag-segmentation {
  width: 138px;
  height: 24px;
  display: flex;
  margin-right: 5px;
  border-radius: 8px;
  text-align: center;
  align-items: center;
  background-color: #b3247c;
}

.tag-text-segmentation {
  margin: 0;
  padding: 0 8px;
  font-size: 12px;
  color: #ffffff;
  line-height: 17px;
  font-family: Rubik, sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tag-segmentation-sum {
  width: 24px;
  height: 24px;
  display: flex;
  margin-right: 5px;
  border-radius: 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #501f99;
}

.segmentation-container {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.user-img {
  width: 32px;
  height: 32px;
}

.img-engagement {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.body-result {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.values-details {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: center;
}

.body-questions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.card-details {
  width: 250px;
  height: 100px;
  padding: 16px;
  margin-right: 16px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.container-question {
  width: calc(33% - 32px);
  display: flex;
  padding: 40px 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #d1d4e3;
}

.container-question:not(:nth-of-type(3n + 1)) {
  margin-left: 32px;
}

.body-survey {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
}

.body-quiz {
  width: 100%;
}

.header-container-question {
  display: flex;
  flex-direction: row;
}

.body-container-question {
  display: flex;
  min-width: 65%;
  max-width: 100%;
  margin-top: 24px;
  align-items: center;
  flex-direction: column;
}

.title-question {
  margin: 0;
  font-size: 20px;
  color: #2f313d;
  line-height: 21px;
  margin-left: 15px;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
}

.img-question-title {
  width: 20px;
  height: 20px;
}

.results {
  font-size: 16px;
}

.results-number {
  font-size: 32px;
}

.color-survey-title {
  width: 16px;
  height: 16px;
  display: flex;
  border-radius: 50%;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}

.details-survey {
  margin-bottom: 10px;
}

.percentage-body {
  width: 80%;
  height: 40px;
  display: flex;
  align-items: center;
  margin: 0px 16px 0px;
  background: #f5f6fa;
}

.percentage-body-survey {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0px 20px 5px;
  align-items: flex-end;
  background: #f5f6fa;
  padding-top: 10px;
}

.graphic-quiz {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.graphic-survey {
  height: 200px;
  width: 100px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: column;
}

.graphic-survey:nth-child(1) .percentage-body-survey {
  margin: 0;
  border-left: 1px solid #d1d4e3;
}

.text-details-question {
  margin: 0;
  font-size: 16px;
  color: #4b4e5c;
  line-height: 19px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
}

.segmentation-container-survey {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.question-body {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  overflow-y: auto;
  max-height: 400px;
  align-items: center;
  background: #ffffff;
  flex-direction: column;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.responses-question {
  width: 100%;
  padding: 16px;
  font-size: 16px;
  color: #4b4e5c;
  line-height: 19px;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
  border-bottom: 1px solid #d1d4e3;
}

.imagicon-eye-form-result {
  width: 20px;
  height: 18px;
  cursor: pointer;
}

.title-graphic-quiz {
  text-align: left;
  justify-content: center;
  margin-bottom: 16px;
}

.title-graphic-survey {
  width: 100%;
  height: 50px;
  padding-top: 5px;
  text-align: center;
  justify-content: center;
  border-top: 1px solid #d1d4e3;
}

.body-graphic-survey {
  display: flex;
  justify-content: center;
}

.body-list-subtitle-options {
  padding-left: 16px;
}

.list-subtitle-options {
  margin-bottom: 5px;
}

.download-report-btn {
  height: 40px;
  border-radius: 5px;
  border: none;
  margin-left: 15px;
  cursor: pointer;
  font-size: 15px;
  transition: 0.15s all;
}
.download-report-btn:hover {
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.11);
}
.download-report-btn:active {
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.11);
  -webkit-filter: brightness(1.15);
          filter: brightness(1.15);
}

.download-report-btn-txt {
  display: inline-block;
  width: 150px;
  height: 18px;
  font-family: Rubik;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  vertical-align: text-top;
  color: rgb(255, 255, 255);
}

.results-form {
  /* background-color: green; */
  margin-left: 20px;
  padding-left: 5px;
  width: 92%;

  border-bottom: 1px solid #e4e4e5;

  display: flex;
  align-items: center;
  justify-content: start;
}

.result-form {
  /* background-color: blue; */
  margin-right: 20px;

  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;

  display: flex;
  align-items: center;
}

.low-opacity {
  opacity: 0.75;
}

.image-row {
  margin-left: 50px;
  padding: 5px;
}

.form-modality-menu {
  display: flex;
  align-items: center;
  color: #b3b3b3;
  flex: 1 1;
  font-size: 18px;
}

.form-modality-label-menu {
  font-weight: 700;
  font-size: 13px;
  line-height: 1.33;
  color: #b3b3b3;
  font-size: 18px;
  width: 20%;
}

.form-group-input-row-menu {
  margin-top: 12px;
}

.form-createoredit {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 60%;
}

.icon-active svg path {
  fill: 'red';
}

.modal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 40px;
}

.modal_title {
  font-size: 22px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b4e5c;
  margin-bottom: 16px;
}

.modal_container_select {
  display: flex;
  grid-gap: 25px;
  gap: 25px;
}

.modal_select {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.modal_select_label {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b4e5c;
}

.modal_link_input {
  width: 98%;
  height: 48px;
  border-radius: 4px;
  background: #e7ebf4;
  border: none;
  color: #2e63fb;
  padding: 5px 5px 5px 15px;

  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: start;
}

.modal_link_input::-webkit-input-placeholder {
  color: #2e63fb;
}

.modal_link_input::placeholder {
  color: #2e63fb;
}

.modal_link_input:focus {
  outline: none;
}

.modal_container_buttons {
  display: flex;
  grid-gap: 25px;
  gap: 25px;
}

.header-historical {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header-child-title {
  width: 100%;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-title-history {
  width: 40%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-historical {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}

.title-historical p {
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: 500;
  color: #333333;
  text-align: left;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.filter-historical {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.body-historical {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid #ececec;
}

.content-list-history {
  width: 100%;
  height: 50px;
  font-size: 13px;
  color: #000000;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.content-list-history img {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 1.5rem;
}

.body-details-first-historical {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.header-section-list {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  justify-content: space-between;
}

.header-full-score {
  height: 4rem;
  display: flex;
  padding: 0 2.5rem;
  align-items: center;
  justify-content: flex-end;
  border-radius: 4px 0px 0px 4px;
  flex-grow: 1;
  grid-gap: 16px;
  gap: 16px;
}

.header-full-score svg {
  cursor: pointer;
  margin-right: 8px;
}

.header-full-score p {
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 16px;
  color: #2f313d;
  font-weight: 600;
  line-height: 41px;
  font-style: normal;
  font-family: Rubik;
}

.header-full-score p:nth-child(3) {
  color: #efc75e;
}

.header-full-score img {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
}

.text-color-historical {
  width: 50%;
  display: flex;
  color: #efc75e;
  align-items: center;
  justify-content: space-between;
}

.text-edit-historical-img {
  justify-content: flex-end;
}

.text-color-historical img {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.text-color-historical img:nth-child(1) {
  cursor: pointer;
}

#img-history-publish {
  margin: 0;
  padding: 0;
  width: 4rem;
  height: 4rem;
  border-radius: 8px;
  margin-right: 16px;
}

.filter-search {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.filter-historical-date {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 25px;
}

.header-title-user-history {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.title-name-user-history {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 16px;
  color: #2f313d;
  font-weight: 500;
  line-height: 19px;
  font-style: normal;
  font-family: Rubik;
}

.img-user-history {
  margin: 0;
  padding: 0;
  width: 4rem;
  height: 4rem;
  margin-left: 5px;
  object-fit: cover;
  margin-right: 16px;
  border-radius: 100%;
  border: 2px solid rgba(125, 134, 161, 0.5);
}

.img-user-history:hover {
  cursor: default;
  border-radius: 100%;
}

.content-user-details-history {
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color: #2f313d;
  line-height: 19px;
  font-style: normal;
  align-items: center;
  font-family: Rubik;
}

.content-user-details-history img {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.content-user-details-history svg {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

#img-publish-details-history {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 1.5rem;
}

.text-color-title-publish {
  color: #efc75e;
}

.content-publish-point-reason {
  display: flex;
  align-items: center;
}

.content-publish-point-reason p {
  margin: 0;
  padding: 0;
  margin-left: 10px;
}

.download-report-btn {
  height: 40px;
  border-radius: 5px;
  border: none;
  margin-left: 15px;
  cursor: pointer;
  font-size: 15px;
  transition: 0.15s all;
}
.download-report-btn:hover {
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.11);
}
.download-report-btn:active {
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.11);
  -webkit-filter: brightness(1.15);
          filter: brightness(1.15);
}

.download-report-btn-txt {
  display: inline-block;
  width: 150px;
  height: 18px;
  font-family: Rubik;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  vertical-align: text-top;
  color: rgb(255, 255, 255);
}

.form-login {
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-top: 5%;
  padding: 50px;
  display: flex;
  width: 50%;
  height: 70%;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.container-login {
  display: flex;
  justify-content: center;
}

.forget-password {
  font-family: Rubik;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
  margin-top: 20px;
  margin-bottom: 20px;
}
body {
  background: #f2f2f2;
}
.forget-password:hover {
  color: black;
  cursor: pointer;
}
input:-webkit-autofill {
  background-color: white !important;
}
input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.btn-area-login {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inside-body-login {
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: white !important;
}

.password-row {
  display: flex;
  width: 256px;
  align-items: center;
  justify-content: flex-end;
}

.toggle-visibility-icon {
  cursor: pointer;
  position: absolute;
  width: 22px;
  margin-top: 18px;
  margin-right: 6px;
}

.g-recaptcha {
  margin-top: 24px;
}

